import { TrashIcon, XIcon } from "@heroicons/react/solid"
import * as React from "react"
import { customAlert } from "./customAlert"
import { Button } from "./styles"

export const CSVInput = ({
  userInput,
  setInput,
}: {
  userInput: UserInputProperties
  setInput: (name: string, value: string, opts?: SetInputOptions) => void
}) => {

  const fileInputRef = React.createRef<HTMLInputElement>()

  const [csvFilename, setCsvFilename] = React.useState('')
  const [csvContent, setCsvContent] = React.useState('')

  React.useEffect(() => {
    setInput(userInput.name, csvContent)
  }, [csvContent])

  const getCsvTemplate = () => {
    const numCols = userInput.columns?.length || 0 // not including leftmost column which just has row headers in it
    const headerRow = ',' + userInput.columns?.join(',')
    const otherRows = userInput.rows?.map((rowVal, index) => {
      return rowVal + ',' + new Array(numCols).fill(0).join(',')
    }) || []
    const csvTemplate = [headerRow, ...otherRows].join('\n')
    return csvTemplate
  }

  const downloadStringAsCsv = (csvString: string, filename: string) => {
    var blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  const downloadTemplate = () => {
    const csvTemplate = getCsvTemplate()
    const filename = userInput.name
    downloadStringAsCsv(csvTemplate, filename)
  }

  const handleUploadCsv = (e: React.ChangeEvent<HTMLInputElement>) => {
    const csvFile = fileInputRef.current?.files?.[0]
    console.log(csvFile)
    if (csvFile) {
      if (csvFile.type !== 'text/csv') {
        customAlert.error('Uploaded file is not a CSV')
        clearCsv()
      } else {
        var fileReader = new FileReader()
        fileReader.onload = function(e) {
          var contents = e.target?.result;
          setCsvFilename(csvFile.name)
          setCsvContent(String(contents))
        }
        fileReader.readAsText(csvFile)
      }
    }
  }

  const downloadCurrentCsv = () => {
    downloadStringAsCsv(csvContent, csvFilename)
  }

  const clearCsv = () => {
    setCsvContent('')
    setCsvFilename('')
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }

  return (
    <>
      <div className="bg-gray-100 rounded p-2 space-y-2">
        {csvFilename && csvContent
          ?
          <>
            <div className="font-medium text-gray-600 underline cursor-pointer hover:opacity-90" onClick={downloadCurrentCsv}>{csvFilename}</div>
            <Button size="small" color="gray" className="inline-flex" onClick={clearCsv}>
              Delete file
              <TrashIcon className="ml-2 w-5 h-5" />
            </Button>
          </>
          :
          <>
            <Button size="small" onClick={downloadTemplate} color="gray" className="!whitespace-normal !h-auto !text-left !leading-5">
              Download CSV template
            </Button>
        
            <input
              className="csv-input hidden"
              type="file"
              ref={fileInputRef}
              placeholder=""
              onChange={handleUploadCsv}
            />
            <Button size="small" onClick={() => fileInputRef.current?.click()}>
              Upload CSV
            </Button>
          </>
        }

      </div>
    </>  
  )
}