import React from "react";
import { Router, RouteComponentProps } from "@reach/router";
import { navigate } from "gatsby";
import PrivateRoute from "../components/privateRoute";
import { ComparableResultsModule } from "../components/comparableResultsModule";
import TEA from "../components/pages/TEA";
import { UnifiedLCATEA } from "../components/pages/LCATEA";
import Cars from "../components/pages/cars";
import PowerSystem from "../components/pages/powerSystem";
import PowerSystemHistorical from "../components/pages/powerSystemHistorical";
import LoginScreen from "../components/pages/login";
import PPS from "../components/pages/pps";
import { PowerPlusHydrogen } from "../components/pages/powerPlusHydrogen";
import { Dashboard } from "../components/pages/dashboard";
import { SingleIndustry, IndustrialFleet } from "../components/pages/industry";
import { UnderConstruction } from "../components/pages/underConstruction";
import { Settings } from "../components/pages/settings";
import { SavedCaseBrowser } from "../components/pages/savedCaseBrowser";
import { HelpPage } from "../components/pages/help";
import { QueryClient, QueryClientProvider } from "react-query";
import { UserProfile } from "../components/pages/userProfile";
import HeavyDutyVehicles from "../components/pages/heavyDutyVehicles";

const AppRedirect = () => {
  navigate('/app/dashboard');
  return null;
}

export const comparableResultsModules: ComparableResultsModuleProps[] = [
  {
    title: 'Cars',
    path: '/app/cars',
    type: 'fleet',
    group: 'systems',
    description: 'Analyze fleet lifecyle emissions',
    allowComparisons: true,
    allowChartTiling: true,
    apiPath: '/fleet',
    component: () => <Cars />
  },
  {
    title: 'Heavy Duty Vehicles',
    path: '/app/fleet-hdv',
    type: 'fleet-hdv',
    group: 'systems',
    description: 'Analyze heavy duty vehicle lifecyle emissions',
    allowComparisons: true,
    allowChartTiling: true,
    apiPath: '/fleet_HDV',
    component: () => <HeavyDutyVehicles />
  },
  {
    title: 'Power Greenfield',
    path: '/app/power-greenfield',
    type: 'pps',
    group: 'systems',
    description: 'Drill down into grid emissions profiles and performance',
    allowComparisons: true,
    apiPath: '/pps',
    component: () => <PPS />
  },
  {
    title: 'Power + Hydrogen',
    path: '/app/power-hydrogen',
    type: 'pph',
    group: 'systems',
    description: 'Explore the interactions between grid and hydrogen',
    allowComparisons: true,
    apiPath: '/pph',
    component: () => <PowerPlusHydrogen />
  },
  {
    title: 'Industrial Fleet',
    path: '/app/industrial-fleet',
    type: 'industrial-fleet',
    group: 'systems',
    description: 'Analyze hard-to-abate sector emissions',
    allowComparisons: true,
    apiPath: '/industry/fleet',
    component: () => <IndustrialFleet />,
  },
  {
    title: 'Build',
    buttonText: 'Paths',
    path: '/app/build',
    type: 'lca-tea',
    group: 'paths',
    description: 'Build and analyze a new pathway',
    allowComparisons: true,
    apiPath: '/lca',
    component: () => <UnifiedLCATEA />
  },
  {
    title: 'Costs',
    path: '/app/costs',
    type: 'tea',
    group: 'paths',
    description: 'Analyze costs of different pathways',
    apiPath: '/tea',
    component: () => <TEA />,
  },
  {
    title: 'Industry',
    path: '/app/industry',
    type: 'industry',
    group: 'paths',
    description: 'Cement, iron & steel, and aluminum manufacturing',
    subModules: [
      {
        type: 'cement',
        title: 'Cement',
      },
      {
        type: 'steel',
        title: 'Iron & Steel',
      },
      {
        type: 'aluminum',
        title: 'Aluminum',
      }
    ],
    allowComparisons: true,
    apiPath: '/industry/cement',
    component: () => <SingleIndustry />,
  },
];

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const App = ({ location }: RouteComponentProps): JSX.Element => {

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <LoginScreen path="/app/login" />
        <PrivateRoute
          path="/app/dashboard"
          title="Dashboard"
          component={() => <Dashboard pathname={location?.pathname || ""} />}
        />
        <PrivateRoute
          path="/app"
          title="App"
          component={() => <AppRedirect />}
        />
        <PrivateRoute
          path="/app/profile"
          title="Profile"
          component={() => <UserProfile />}
        />
        <PrivateRoute
          path="/app/saved"
          title="Saved"
          component={() => <SavedCaseBrowser />}
        />
        <PrivateRoute
          path="/app/help"
          title="Help"
          component={() => <HelpPage />}
        />
        {comparableResultsModules.map(moduleData => (
          <PrivateRoute
            path={moduleData.path}
            key={moduleData.type}
            component={() => (
              <ComparableResultsModule moduleData={moduleData}>
                {moduleData.component()}
              </ComparableResultsModule>
            )}
          />
        ))}
        <PrivateRoute
          path="/app/settings"
          title="Settings"
          component={() => (
            <Settings title="Settings" />
          )}
        />
      </Router>
    </QueryClientProvider>
  )
}

export default App;
