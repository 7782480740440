import * as React from "react";
import * as Sentry from "@sentry/browser";
import { db, getIncrementedCaseNamesForModule, useRefreshSavedCases, useRefreshSavedBatches, useSavedCases, getSavedCaseDetail, useSavedBatchDetail, useSavedBatches, promptName, useDemoContent } from "../hooks/useDB";
import { getInputValuesRecordFromInputStates } from "../hooks/useUserInputs";
import { generateUniqueIntId, getHeaderTitle, triggerResize, unique } from "../utils";
import { maxCasesPerBatch, maxComparisonResultCols } from "../utils/constants";
import { trigger } from "../utils/events";
import { customAlert } from "./customAlert";
import SEO from "./seo";
import useClient from "../hooks/useClient";
import { useAtomValue } from "jotai";
import { isFocusLinkingDisabledAtom } from "../store/store";
import { authenticatedClient } from "../utils/client";
import { useQueryClient } from "react-query";
import {  } from "../hooks/useDB";

export const ModuleStateContext = React.createContext<Partial<ModuleStateProps>>({});
export const ModuleDispatchContext = React.createContext({} as React.Dispatch<ModuleActionProps>);

const getEmptyComparisonCase = (): ComparisonCase => ({
  id: generateUniqueIntId(),
  inputGroupOpenStates: {},
  focusedInputs: [],
});
const getEmptyComparisonCaseWithNullInputValues = (): ComparisonCase => {
  return {
    ...getEmptyComparisonCase(),
    data: {
      inputValues: {}
    }
  }
}

const areAllArrayElementsEqual = (arr: Array<unknown>) => arr.every(val => val === arr[0]);

const updateChartControlAllocation = (state: ModuleStateProps): ModuleStateProps => {
  const existentComparisonCaseIds = state.comparisonCases
    .filter(c => state.caseIdsActiveInComparisonView.includes(c.id)) // filter out cases that aren't displayed in active comparison cols
    .filter(c => !!c.data?.analysisResult || !!c.savedCaseId) // filter out cases that don't have results / charts yet
    .map(c => c.savedCaseId || c.id);
  const areAllComparisonResultsTheSame = areAllArrayElementsEqual(existentComparisonCaseIds);
  const chartControlAllocation = areAllComparisonResultsTheSame ? 'individual' : 'group';
  return {
    ...state,
    chartControlAllocation: chartControlAllocation
  }
}

const appendEmptyComparisonCase = (state: ModuleStateProps): ModuleStateProps => {
  triggerResize();
  let newState: ModuleStateProps = {
    ...state,
    isComparisonMode: true,
    comparisonCases: [...state.comparisonCases].concat([getEmptyComparisonCaseWithNullInputValues()]),
    numChartCols: 1,
    isAnyColumnFullscreened: false,
  };
  newState = trackCaseIdsInComparison(newState)
  newState = alignCaseFocusStates(newState);
  return newState;
}

const postProcessState = (state: ModuleStateProps): ModuleStateProps => {
  let newState = { ...state }
  newState = updateChartControlAllocation(newState);
  newState = alignCaseFocusStates(newState);
  newState = trackCaseIdsInComparison(newState);
  if (!newState.isComparisonMode && newState.comparisonCases.length > 1) {
    newState.isComparisonMode = true
  }
  return newState
}

/**
 * Basically, what we're doing here is pruning unused case IDs out of caseIdsActiveInComparisonView,
 * and automatically adding new cases to the active caseIdsActiveInComparisonView array,
 * i.e. the ones whos inputs and charts are actually displayed now, until we fill up to the max # of comparison
 * result cols.
 * @param state 
 * @returns 
 */
const trackCaseIdsInComparison = (state: ModuleStateProps, opts?: { fillToMaxCols?: boolean } ): ModuleStateProps => {
  let newState = {
    ...state
  }
  // prune out no-longer-used case IDs
  const currentCaseIds = newState.comparisonCases.map(c => c.id)
  newState = {
    ...newState,
    caseIdsActiveInComparisonView: newState.caseIdsActiveInComparisonView.filter(caseId => currentCaseIds.includes(caseId))
  }
  // add new cases to the caseIdsActiveInComparisonView until we fill up to the max # of comparison result cols
  const maxToFillTo = opts?.fillToMaxCols ? maxComparisonResultCols : 1
  newState.comparisonCases.forEach(comparisonCase => {
    if (newState.caseIdsActiveInComparisonView.length < maxToFillTo) { // maxComparisonResultCols) {
      if (!newState.caseIdsActiveInComparisonView.includes(comparisonCase.id)) {
        newState.caseIdsActiveInComparisonView.push(comparisonCase.id)
      }
    }
  })
  return newState
}

/**
 * Align comparison case focus states.
 * This should only do anything if focus link is active.
 * 
 * @param state 
 * @returns modified state
 */
const alignCaseFocusStates = (state: ModuleStateProps): ModuleStateProps => {
  if (!state.isFocusLinkActive) {
    return state;
  }
  const prototypeCasesWithFocusedInputs = state.comparisonCases.filter(c => c.focusedInputs && c.focusedInputs.length > 0);
  const prototypeCase = prototypeCasesWithFocusedInputs?.[0]
  if (prototypeCase) {
    const prototypeCaseIndex = state.comparisonCases.indexOf(prototypeCase)
    const newState = {
      ...state,
      comparisonCases: state.comparisonCases.map((comparisonCase, index) => {
        if (index === prototypeCaseIndex) {
          return comparisonCase;
        } else {
          return {
            ...comparisonCase,
            focusedInputs: prototypeCase.focusedInputs,
            isFocusModeActive: prototypeCase.isFocusModeActive,
          }
        }
      })
    }
    return newState;
  } else {
    return state;
  }
}

export const getCaseNameFromComparisonCaseAtIndex = (comparisonCase: ComparisonCase | undefined, index: number) => {
  return comparisonCase?.name || `New Case ${index + 1}`;
}

const reducer = (state: ModuleStateProps, action: ModuleActionProps): ModuleStateProps => {
  if (process.env.NODE_ENV === 'development') {
    console.log('REDUCER: ', action);
  }
  let newState: ModuleStateProps;
  let newComparisonCases: ComparisonCase[];
  switch (action.type) {
    case 'setColumnFullscreen':
      const isSettingToFullscreen = action.value
      // setIsSidebarOpenWithNewSidebarAndFullscreenState(wasSidebarSetOpen, action.value as boolean);
      return {
        ...state,
        isAnyColumnFullscreened: isSettingToFullscreen as boolean,
        numChartCols: isSettingToFullscreen ? 3 : 1
      };
    case 'setNumChartCols':
      return { ...state, numChartCols: action.value as number };
    case 'appendEmptyComparisonCase':
      newState = {
        ...state,
      }
      newState = appendEmptyComparisonCase(newState)
      return newState
    // case 'addComparisonCol':
    //   if (state.caseIdsActiveInComparisonView.length >= maxComparisonResultCols) {
    //     return state
    //   }
    //   newState = {
    //     ...state,
    //   }
    //   newState = appendEmptyComparisonCase(newState) 
    //   // newState = updateChartControlAllocation(newState);
    //   newState = postProcessState(newState)
    //   return newState;
    case 'setModuleType':
      return {
        ...state,
        type: action.value
      }
    case 'appendComparisonCol':
      // also append an empty case if we're starting from one comparison case, else we just add an empty comparison column, which will get populated with an existing case in the batch in postProcess function
      newState = {
        ...state,
        caseIdsActiveInComparisonView: [...state.caseIdsActiveInComparisonView].concat(undefined)
      }
      console.log('newState before:', newState)

      // if, after adding a comparison col, we don't have enough comparisonCases to fill it, append an empty one
      if (newState.caseIdsActiveInComparisonView.length > newState.comparisonCases.length) {
        newState = appendEmptyComparisonCase(newState)
      } else {
        // find the first unused comparisoncase and insert it into the new comparison col
        const firstUndisplayedComparisonCaseId = newState.comparisonCases.filter(comparisonCase => !newState.caseIdsActiveInComparisonView.includes(comparisonCase.id))?.[0]?.id
        if (firstUndisplayedComparisonCaseId) {
          newState.caseIdsActiveInComparisonView[newState.caseIdsActiveInComparisonView.length - 1] = firstUndisplayedComparisonCaseId
        }
      }
      
      console.log('newState after:', newState)
      // newState = postProcessState(newState)
      return newState
    case 'setIsCaseLoadingAtIndex':
      return {
        ...state,
        comparisonCases: state.comparisonCases.map((comparisonCase, index) => {
          if (index !== action.index) {
            return comparisonCase;
          }
          return {
            ...comparisonCase,
            isLoading: action.value,
          }
        })
      };
    case 'setActiveComparisonCaseIdAtIndex':
      if (typeof action.index !== 'number') {
        throw new Error('No index provided')
      }
      if (typeof action.index === 'number' && action.index > state.caseIdsActiveInComparisonView.length - 1) {
        throw new Error(`Error setting active comparison case id at index "${action.index}" - index out of bounds versus current caseIdsActiveInComparisonView`)
      }
      newState = {
        ...state,
      }
      newState.caseIdsActiveInComparisonView[action.index] = action.value
      newState = postProcessState(newState)
      return newState
    case 'setComparisonCaseIdAtIndex':
      var id = action.value;
      if (typeof action.index !== 'number') {
        return state;
      }
      // reformat id from select option value to proper value - e.g. we don't want '' empty string, we want null instead (for proper downstream functionality based on null id value)
      switch (id) {
        case '':
          id = null;
          break;
        case 'unsaved':
          break;
        default:
          break;
      }
      const isIdFromSavedCase = id !== null && id !== 'unsaved';

      newState = {
        ...state,
      }

      // if we're trying to set a case ID for a case index that doesn't exist yet, add an empty case first
      if (action.index > (newState.comparisonCases.length - 1)) {
        newState = appendEmptyComparisonCase(newState)
      }

      newState.comparisonCases = newState.comparisonCases.map((comparisonCase, index) => {
        if (index !== action.index) {
          return comparisonCase;
        }
        const newCase: ComparisonCase = {
          id,
        }
        if (isIdFromSavedCase) {
          newCase.isUnsaved = false
          newCase.savedCaseId = id
        }
        return newCase
      })

      // maybe in load case dropdown, there's a dialog that says, replace this case (name X), or keep it?
      // and if replace, we actually replace this comparisonCase index,
      // otherwise we just append it to the end of comparisonCases

      // if we're replacing a saved case in active compare with another one, what do we do?
      // append the saved case to comparisonCases and set the correct index of caseIdsInComparison to this case Id?

      if (isIdFromSavedCase) {
        const idOfCaseWeAreReplacing = state.comparisonCases?.[action.index]?.id
        const activeComparisonIndexOfCaseWeAreReplacing = state.caseIdsActiveInComparisonView.indexOf(idOfCaseWeAreReplacing)
        if (activeComparisonIndexOfCaseWeAreReplacing >= 0) {
          newState.caseIdsActiveInComparisonView[activeComparisonIndexOfCaseWeAreReplacing] = id
        }
      }

      newState = postProcessState(newState)
      // newState = updateChartControlAllocation(newState);
      // newState = trackCaseIdsInComparison(newState)
      return newState;
    case 'setComparisonCasePropsAtIndex':
      newState = modifyCaseByIndexWithProps(state, action.index, action.value);
      return newState;
    case 'resetComparisonCaseAtIndex':
      newState = {
        ...state,
        comparisonCases: state.comparisonCases.map((comparisonCase, index) => {
          if (index === action.index) {
            return getEmptyComparisonCaseWithNullInputValues()
          } else {
            return comparisonCase
          }
        })
      }
      newState = postProcessState(newState)
      // newState = updateChartControlAllocation(newState)
      // newState = alignCaseFocusStates(newState)
      // newState = trackCaseIdsInComparison(newState)
      return newState
    case 'setComparisonCaseAtIndex':
      newState = {
        ...state
      };

      newState.comparisonCases[action.index] = action.value;

      newState = postProcessState(newState)
      // newState = checkSubModuleAlignment(newState);
      return newState;
    case 'setComparisonCaseDataAtIndex':
      return {
        ...state,
        comparisonCases: state.comparisonCases.map((comparisonCase, index) => {
          if (index !== action.index) {
            // This isn't the item we care about - keep it as-is
            return comparisonCase
          }
          // Otherwise, this is the one we want - return an updated value
          return {
            ...comparisonCase,
            isLoading: false,
            isRunning: false,
            data: action.value,
          } as ComparisonCase
        })
      }
    case 'deleteSavedCaseIds':
      const savedCaseIds = action.value;
      newComparisonCases = state.comparisonCases.slice().filter(comparisonCase => {
        return !savedCaseIds.includes(comparisonCase.savedCaseId)
      });
      newState = {
        ...state,
        comparisonCases: newComparisonCases,
      }
      if (newComparisonCases.length === 0) { // we just deleted all active cases, so reset comparisonCases to one empty case and clear saved batch if there is one
        newState.comparisonCases = [getEmptyComparisonCase()];
        delete newState.savedBatch;
      }
      newState = postProcessState(newState)
      return newState;
    case 'removeComparisonColAtIndex':
      if (typeof action.index !== 'number') {
        return state;
      }
      // newState = {
      //   ...state,
      // }
      let caseIdsActiveInComparisonView = [...state.caseIdsActiveInComparisonView]
      caseIdsActiveInComparisonView.splice(action.index, 1)
      newState = {
        ...state,
        caseIdsActiveInComparisonView,
        isComparisonMode: true
      }
      triggerResize()
      return newState
    case 'removeComparisonCaseAtIndex':
      // if we're removing the last one, just return an empty comparison case
      if (state.comparisonCases.length === 1) {
        newComparisonCases = [getEmptyComparisonCase()];
      } else {
        newComparisonCases = state.comparisonCases.slice().filter((item, index) => index !== action.index)
      }
      const isComparisonMode = newComparisonCases.length > 1 ? true : false;
      triggerResize();
      newState = {
        ...state,
        isComparisonMode: isComparisonMode,
        comparisonCases: newComparisonCases,
        savedBatch: undefined,
      };
      // if (newState.comparisonCases.length === 1) {
      //   newState.isFocusLinkActive = false
      // }
      newState = postProcessState(newState)
      return newState;
    case 'clearCaseAtComparisonColumnIndex': // but preserve current user input values
      if (typeof action.index !== 'number') {
        break;
      }
      const thisCaseId = state.caseIdsActiveInComparisonView[action.index];
      // const thisCaseIndex = state.comparisonCases.
      const thisCase = state.comparisonCases.find(c => c.id === thisCaseId);
      // const thisCaseKeys = Object.keys(thisCase);

      const isThisCaseAlreadyCleared = !thisCase?.savedCaseId || (!thisCase?.data?.analysisResult && !thisCase?.data?.customData);
      // const isThisCaseAlreadyCleared = thisCaseKeys?.length === 1 && thisCaseKeys.includes('id'); // this means we only have an id stored (randomly generated), but no other saved case data)
      // const isThisCaseAlreadyCleared = JSON.stringify(state.comparisonCases[action.index]) === JSON.stringify(getEmptyComparisonCase());
      // if (isThisCaseAlreadyCleared) {
      //   console.log('this case is aready cleared')
      //   return state;
      // }
      newState = {
        ...state,
        savedBatch: undefined,
        comparisonCases: state.comparisonCases.map((comparisonCase, index) => {
          if (comparisonCase.id !== thisCaseId) {
            return comparisonCase;
          }
          return {
            // POTENTIAL BUG: we want to set a new ID probably? b/c what if this is a saved case? we don't want to preserve the id if it's from a saved case...
            id: thisCase?.id, // preserve the case id, because otherwise all input values will be cleared since input handler component uses this id as its key - and we want to retain input values when you change one input - we just want to clear the charts
            inputGroupOpenStates: thisCase?.inputGroupOpenStates,
            focusedInputs: thisCase?.focusedInputs,
          };
        })
      }
      newState = postProcessState(newState)
      // newState = updateChartControlAllocation(newState);
      // newState = alignCaseFocusStates(newState);
      return newState;
    case 'resetComparisonCasesToOneEmptyCase':
      newState = {
        ...state,
        isComparisonMode: false,
        comparisonCases: [getEmptyComparisonCase()],
      }
      delete newState.savedBatch
      // newState = updateChartControlAllocation(newState);
      newState = postProcessState(newState)
      return newState;
    case 'duplicateCaseAtIndexWithData':
      if (state.comparisonCases.length >= maxCasesPerBatch) {
        console.log(state)
        throw new Error('Can\'t duplicate case since we\'re already at the maximum number of cases');
      }
      let newData = action.value;
      if (!newData) {
        throw new Error('No data passed to duplicateCaseAtIndex dispatch action');
      }
      let dupe = JSON.parse(JSON.stringify(state.comparisonCases[action.index as number]));
      dupe.isUnsaved = true;
      if (dupe.id) {
        dupe.id = generateUniqueIntId(); // replace id if it exists, otherwise it's probably null, so leave it at that
      }
      if (!dupe.data) {
        dupe.data = newData;
      } else { // if case we're duping already had data, we still want to preserve input group open states
        dupe.data.inputGroupOpenStates = newData.inputGroupOpenStates || {};
      }
      if (newData.customData) {
        dupe.data.customData = newData.customData;
      }
      if (dupe.data) {
        dupe.data.analysisResult = null; // don't duplicate chart data, just inputs and name and such
      }
      delete dupe.name;
      // if (dupe.name) {
      //   dupe.name += ' copy';
      // }
      delete dupe.savedCaseId;
      delete dupe.data?.savedCaseId;
      delete dupe.data?.id;

      newState = {
        ...state,
        comparisonCases: state.comparisonCases.concat(dupe),
        isComparisonMode: true,
      }

      // add this case to visible cases if we don't have full cols yet
      // if (newState.caseIdsActiveInComparisonView.length < maxComparisonResultCols) {
      const dupedCaseId = newState.comparisonCases[action.index].id
      const indexToInsertAt = newState.caseIdsActiveInComparisonView.indexOf(dupedCaseId) + 1
      newState.caseIdsActiveInComparisonView.splice(indexToInsertAt, 0, dupe.id) // insert dupe case id into visible case ids right after duped case
      // }
      // trim the visible case IDs array if it's too long
      while (newState.caseIdsActiveInComparisonView.length > maxComparisonResultCols) {
        // if the dupe we just inserted is at the end of the array, trim the beginning so it's within max # of cols, i.e. bump off the first visible case
        if (newState.caseIdsActiveInComparisonView.indexOf(dupe.id) === newState.caseIdsActiveInComparisonView.length - 1) {
          // console.log('shift')
          newState.caseIdsActiveInComparisonView.shift()
        } else { // otherwise trim the end of the array, i.e. bump off the last visible case
          // console.log('pop')
          newState.caseIdsActiveInComparisonView.pop()
        }
      }

      newState = postProcessState(newState)
      // newState = updateChartControlAllocation(newState);
      triggerResize();
      return newState;
    case 'setCaseIdsActiveInComparisonView':
      return {
        ...state,
        caseIdsActiveInComparisonView: action.value.slice(0, maxComparisonResultCols)
      }
    case 'setCaseToRunningAtIndex':
      return {
        ...state,
        comparisonCases: state.comparisonCases.map((comparisonCase, index) => {
          if (action.index !== index) {
            return comparisonCase;
          }
          return {
            ...comparisonCase,
            data: undefined,
            isRunning: true,
            highlightErroneousInputs: false,
          } as ComparisonCase
        })
      };
    case 'stopRunningCaseAtIndex':
      return {
        ...state,
        comparisonCases: state.comparisonCases.map((comparisonCase, index) => {
          if (action.index !== index) {
            return comparisonCase;
          }
          return {
            ...comparisonCase,
            // data: undefined,
            isRunning: false,
          } as ComparisonCase
        })
      }
    case 'setFocusedInputsAtIndex':
      const areWeClearingFocusedInputs = action.value.length === 0

      newState = {
        ...state,
        comparisonCases: state.comparisonCases.map((comparisonCase, index) => {
          if (state.isFocusLinkActive && areWeClearingFocusedInputs) {
            return {
              ...comparisonCase,
              focusedInputs: [],
              isFocusModeActive: false,
            }
          } else {
            if (index === action.index) {
              return {
                ...comparisonCase,
                focusedInputs: action.value,
                isFocusModeActive: false,
              }
            } else {
              return comparisonCase;
            }
          }
        })
      }
      return newState;
    case 'toggleFocusInputAtCaseIndex':
      const inputName = String(action.value);
      let focusedInputsForCase = state.comparisonCases?.[action.index]?.focusedInputs?.slice() || [];
      const isInputAlreadyFocused = focusedInputsForCase.includes(inputName);
      const isFocusing = !isInputAlreadyFocused

      newState = {
        ...state,
        comparisonCases: [...state.comparisonCases].map((comparisonCase, comparisonIndex) => {
          // when focus link mode is active, also toggle this input focus state in all other active cases (same direction as we're doing it in the target case) - otherwise just return those cases unmodified
          if (!state.isFocusLinkActive && comparisonIndex !== action.index) {
            return comparisonCase;
          }
          let newFocusedInputs = comparisonCase?.focusedInputs?.slice() || [];
          if (isFocusing) {
            newFocusedInputs.push(inputName);
          } else {
            newFocusedInputs = newFocusedInputs.filter(input => input !== inputName)
          }
          return {
            ...comparisonCase,
            focusedInputs: unique(newFocusedInputs)
          }
        })
      }

      // if there are any cases with no focused inputs, disable focus mode
      if (!newState.comparisonCases.every(comparisonCase => comparisonCase?.focusedInputs && comparisonCase?.focusedInputs.length > 0)) {
        newState.isFocusModeActive = false;
      }

      // if (isInputAlreadyFocused) {
      //   focusedInputs = focusedInputs.filter(name => name !== inputName)
      // } else {
      //   focusedInputs.push(inputName);
      // }
      // newState = {
      //   ...state,
      //   focusedInputs: unique(focusedInputs) || [],
      // }
      // if (focusedInputs.length === 0) {
      //   newState.isFocusModeActive = false;
      // }
      return newState;
    case 'toggleFocusModeAtIndex':
      const isActivating = !state.comparisonCases?.[action.index]?.isFocusModeActive;
      // applyto all cases if focus linking isn't disabled, and if focus link is active

      const shouldApplyToAllCases = state.isFocusLinkActive && !action.options?.ignoreFocusLinking
      return {
        ...state,
        comparisonCases: state.comparisonCases.map((comparisonCase, index) => {
          if (shouldApplyToAllCases || index === action.index) {
            return {
              ...comparisonCase,
              isFocusModeActive: isActivating,
            }
          } else {
            return comparisonCase;
          }
        })
        // isFocusModeActive: !state.isFocusModeActive,
        // focusedInputs: isDeactivating ? [] : [...state.focusedInputs]
      }
    case 'disableFocusModeForAllCases':
      return {
        ...state,
        // isFocusLinkActive: false,
        comparisonCases: state.comparisonCases.map(comparisonCase => ({
          ...comparisonCase,
          isFocusModeActive: false,
        }))
      }
    case 'toggleInputGroupOpenStateAtComparisonIndex':
      return {
        ...state,
        comparisonCases: state.comparisonCases.map((comparisonCase, index) => {
          if (index !== action.index) {
            return comparisonCase;
          }
          if (!comparisonCase.inputGroupOpenStates) comparisonCase.inputGroupOpenStates = {}
          const wasInputGroupOpen = comparisonCase.inputGroupOpenStates?.[action.value];
          comparisonCase.inputGroupOpenStates[action.value] = !wasInputGroupOpen;
          return comparisonCase;
        })
      }
    case 'clearInputGroupOpenStatesAtCaseIndex':
      return modifyCaseByIndexWithProps(state, action.index, { inputGroupOpenStates: {} })
    case 'setIsFocusLinkActive':
      newState = {
        ...state,
        isFocusLinkActive: action.value,
      }
      newState = alignCaseFocusStates(newState)
      return newState
    case 'toggleIsFocusLinkActiveWithIndex':
      newState = {
        ...state,
        isFocusLinkActive: !state.isFocusLinkActive,
      }
      // if we're enabling focus link, duplicate this case's focus state to others
      if (newState.isFocusLinkActive) {
        const prototypeCase = state.comparisonCases?.[action.index]
        newState.comparisonCases = newState.comparisonCases.map((comparisonCase, index) => {
          // duplicate focus state to other active cases
          if (index !== action.index) {
            return {
              ...comparisonCase,
              focusedInputs: [...prototypeCase.focusedInputs ?? []],
              isFocusModeActive: prototypeCase.isFocusModeActive,
            }
          } else {
            return comparisonCase
          }
        })
      }
      return newState;
    case 'loadBatch':
      const batchToLoad = action.value as SavedBatchInBackendDB
      newState = {
        ...state,
        savedBatch: {
          id: batchToLoad.id,
        },
        comparisonCases: batchToLoad.cases.map(caseId => ({
          id: generateUniqueIntId(),
          savedCaseId: caseId,
          isFocusModeActive: false,
        })),
        // isComparisonMode: true
      }
      // newState.caseIdsActiveInComparisonView = newState.comparisonCases.map(c => c.id)
      newState = trackCaseIdsInComparison(newState, { fillToMaxCols: true })
      newState = postProcessState(newState)
      return newState
    // case 'loadBatchId':
    //   const batchId = Number(action.value);
    //   if (typeof action.dispatch !== 'function') throw new Error('Dispatch function should have been passed into dispatch call')
    //   // newState = { ...state, }

    //   // we need to load the relevant cases from this batch's id...

    //   // FIX THIS
    //   return {
    //     ...state,
    //     savedBatch: {
    //       id: batchId
    //     }
    //   }
    case 'setBatch':
      const batch = action.value;
      newState = {
        ...state,
        savedBatch: batch,
      }
      return newState;
    case 'setBatchProps':
      const props = action.value;
      newState = {
        ...state,
        savedBatch: {
          ...state.savedBatch,
          ...props
        }
      }
      return newState;
    case 'enableErroneousInputHighlightingAtIndex':
      newState = modifyCaseByIndexWithProps(state, action.index, { highlightErroneousInputs: true });
      return newState;
    // case 'toggleCaseSelectedAtIndex':
    // newState = modifyCaseByIndexWithProps(state, action.index)
    default:
      Sentry.captureEvent({
        message: `Error: Reducer (ComparableResultsModule)`,
        extra: {
          err: `Error: action of type ${action.type} is not allowed in reducer`,
          action: action,
        },
      });
      console.error('Error: action of type ', action.type, ' is not allowed in reducer')
      return state;
  }
}

const modifyCaseByIndexWithProps = (state: ModuleStateProps, index: number, props: object): ModuleStateProps => {
  return {
    ...state,
    comparisonCases: state.comparisonCases.map((comparisonCase, comparisonIndex) => {
      if (comparisonIndex !== index) {
        return comparisonCase;
      }
      return {
        ...comparisonCase,
        ...props,
        // data: undefined,
        // isRunning: false,
      } as ComparisonCase
    })
  }
}

export const useSaveCaseAtIndex = () => {

  const { client } = useClient()
  const dispatch = React.useContext(ModuleDispatchContext)
  const refreshSavedCases = useRefreshSavedCases()

  const saveCaseAtIndex = async ({
    name,
    index,
    comparisonCases,
    data,
    moduleType,
    suppressNotices,
  }: {
    name?: string | null;
    index: number | undefined;
    comparisonCases: ComparisonCase[];
    data: {
      inputStates?: Record<string, InputState>; // Paths won't have inputStates, since it has multiple sub-modules with their own input states
      customData?: any;
      moduleVersion: ModuleVersion; // Paths sends a record due to multiple sub-modules, but other modules send a single version number
    };
    moduleType: string;
    suppressNotices?: boolean;
  }): Promise<SavedCaseInBackendDB | undefined> => {

    if (typeof index !== 'number' || typeof dispatch !== 'function') {
      throw new Error('Can\'t save case because either index or dispatch was not provided')
    }
    if (!moduleType) {
      throw new Error('Module type not provided to saveCaseAtIndex function');
    }

    if (!name) {
      name = await promptName()
    }

    if (name) {
      const comparisonCase = comparisonCases[index]
      const analysisResult = comparisonCase?.data?.analysisResult;
      const inputStates = data.inputStates;
      const customData = data.customData;
      const moduleVersion = data.moduleVersion;
      const caseId = comparisonCases?.[index]?.id;
      const inputValues = inputStates ? getInputValuesRecordFromInputStates(inputStates) : {};

      // switch to backend saving

      const body: SavedCasePut = {
        name: name,
        version: moduleVersion,
        inputs: {
          inputValues,
          customData,
          focusedInputs: comparisonCase.focusedInputs,
        },
        outputs: analysisResult,
        module: moduleType,
      }

      console.log(body)

      try {
        const response = await client(
          '/cases/',
          {
            method: 'POST',
            body,
          }
        )
        const savedCase: SavedCaseInBackendDB = response

        if (!suppressNotices) {
          customAlert.success('Saved')
        }
        // invalidate stale saved cases queries
        refreshSavedCases({ caseIds: [response.id] })

        dispatch && dispatch({
          type: 'setComparisonCaseAtIndex',
          value: {
            ...comparisonCase,
            id: caseId,
            savedCaseId: response.id,
            isUnsaved: false,
            name,
            data: {
              savedCaseId: response.id,
              analysisResult,
              inputValues,
              customData,
              moduleVersion,
            },
          },
          index
        });

        return savedCase

      } catch (error) {
        console.log(error)
      }
    }
  }
  return saveCaseAtIndex;
}

export const useSaveBatch = () => {

  const saveCaseAtIndex = useSaveCaseAtIndex()
  const refreshSavedCases = useRefreshSavedCases()
  const refreshSavedBatches = useRefreshSavedBatches()
  const { client } = useClient()

  const saveBatch = async ({
    moduleType,
    comparisonCases,
    unsavedCaseData,
    dispatch,
  }: {
    moduleType: string;
    comparisonCases: ComparisonCase[];
    unsavedCaseData?: Array<{
      inputStates?: Record<string, InputState>;
      customData?: any;
    } | undefined>;
    dispatch?: React.Dispatch<ModuleActionProps>;
  }) => {


    promptName().then(batchName => {
      if (batchName) {
        // save any cases that aren't saved yet
        const numUnsavedCases = comparisonCases.filter(c => {
          const isUnsaved = c.isUnsaved || typeof c.savedCaseId !== 'number';
          return isUnsaved;
        })?.length || 0;
        // get multiple valid auto-incremented case names (might need zero of these, if all cases are saved already)
        getIncrementedCaseNamesForModule({
          startingName: batchName,
          moduleType: moduleType,
          numCases: numUnsavedCases,
        }).then(async (incrementedCaseNames) => {
          
          // need to save all unsaved cases, and get their resulting saved case IDs (to save w/batch)
          const promises: Promise<{id: number} | SavedCaseInBackendDB | undefined>[] = [];
          comparisonCases.forEach((comparisonCase, comparisonIndex) => {
            const isUnsaved = typeof comparisonCase.savedCaseId !== 'number';
            if (isUnsaved) {
              // save case, popping one of the incremetedCaseNames to use
              const caseName = incrementedCaseNames.shift();
              if (!unsavedCaseData?.[comparisonIndex]?.inputStates && !unsavedCaseData?.[comparisonIndex]?.customData) {
                throw new Error('No input states or custom data provided for one or more unsaved cases when saving batch')
              }
              promises.push(saveCaseAtIndex({
                name: caseName || '',
                index: comparisonIndex,
                comparisonCases,
                data: unsavedCaseData[comparisonIndex],
                moduleType: moduleType,
                dispatch,
                suppressNotices: true,
              }))
              // return caseId;
            } else {
              // push dummy promise to just return the already-existing saved case ID, if the case is already saved, for the Promises.all call below - this way we preserve order and make sure all unsaved + saved cases get into the batch
              promises.push(new Promise<{id: number}>((resolve, reject) => {
                resolve(comparisonCase?.savedCaseId ? {id: comparisonCase.savedCaseId} : undefined)
              }));
            }
          });

          Promise.all(promises).then(savedCases => {

            const savedCaseIds = savedCases.map(s => s.id)

            savedCaseIds.forEach(id => {
              if (typeof id !== 'number') {
                throw new Error('One or more case ids were not numbers: ' + JSON.stringify(savedCaseIds));
              }
            })

            const newBatch: SavedBatchPut = {
              cases: savedCaseIds,
              name: batchName,
              module: moduleType,
            }

            console.log(newBatch)

            client(
              '/batches/',
              {
                method: 'POST',
                body: newBatch,
              }
            ).then(data => {
              console.log('RESPONSE:', data)
              const savedBatch = data as SavedBatchInBackendDB
              customAlert.success('Batch saved')
              refreshSavedBatches({batchIds: [savedBatch.id]})
              if (dispatch) {
                dispatch({
                  type: 'setBatch',
                  value: {
                    // ...newBatch,
                    id: data.id // the id of the saved batch in the DB
                  }
                })
              }
            }).catch(err => {
              console.log(err);
              Sentry.captureEvent({
                message: `Error saving batch`,
                extra: {
                  err: JSON.stringify(err),
                },
              });
            });
          }).catch(err => {
            console.log(err);
            Sentry.captureEvent({
              message: `Error resolving promises to save auto-incremented unsaved cases when saving batch`,
              extra: {
                err: JSON.stringify(err),
              },
            });
          });
        }).catch(err => {
          console.log(err);
          Sentry.captureEvent({
            message: `Error in getIncrementedCaseNamesForModule`,
            extra: {
              err: JSON.stringify(err),
            },
          });
        });
      }
    })
  }

  return saveBatch
}

export const useRunCaseAtIndex = () => {

  const { client } = useClient()
  const dispatch = React.useContext(ModuleDispatchContext)

  const runCaseAtIndex = async ({
    caseIndex,
    comparisonCase,
    apiEndpoint,
    inputStates,
    // customBody,
    customRequests,
    customData,
    isValid,
    setError,
  }: {
    caseIndex: number;
    comparisonCase: ComparisonCase | null | undefined;
    apiEndpoint?: string;
    inputStates?: Record<string, InputState>;
    // customBody?: any;
    customRequests?: APIRequestWithType[];
    customData?: unknown;
    isValid: boolean;
    setError: React.Dispatch<React.SetStateAction<string>>;
  }) => {
    if (!isValid) {
      customAlert({ message: 'There are one or more invalid inputs that need to be corrected before running' })
      return;
    }

    const isCaseAlreadyRun = !!comparisonCase?.data?.analysisResult;
    if (isCaseAlreadyRun) {
      return null;
    }

    // set case to loading
    dispatch({ type: 'setCaseToRunningAtIndex', index: caseIndex });
    let body: Record<string, any> = {};
    let requests: APIRequest[] = [];
    const promises: Promise<Record<string, unknown> | undefined>[] = [];
    if (!customRequests && !apiEndpoint) {
      // Sentry.captureEvent({
      //   message: `Error: ${}`,
      //   extra: {
      //     err: JSON.stringify(err),
      //     pathway: JSON.stringify(formattedPathways),
      //   },
      // });
      throw new Error('runCaseAtIndex must receive either customRequests or apiEndpoint, but got neither.')
    }
    if (customRequests) {
      requests = customRequests;
      // body = customBody;
    }
    else if (apiEndpoint) {
      for (let name in inputStates) {
        body[name] = inputStates[name].value;
      }
      requests = [{
        endpoint: apiEndpoint,
        body,
      }];
    } else {
      // fallthrough case - this should never happen - either customRequests or apiEndpoint must be defined
    }
    requests.forEach(request => {
      promises.push(
        client(request.endpoint, {
          body: request.body
        })
      );
    });
    try {
      setError("");
      console.log('trying...');
      const startTime = new Date().getTime();
      const responses = await Promise.all(promises).catch((e) => {
        console.log(e);
        const error = e.error || (e.message.indexOf('Unexpected token') > -1 ? 'There was an error running this analysis. We have logged the error automatically and will fix it as soon as we can.' : e.message);
        // dispatch({type: 'setErrorAtIndex', index: comparisonIndex, value: error});
        setError(error);
        dispatch({ type: 'stopRunningCaseAtIndex', index: caseIndex });
      });
      // console.log(responses);
      if (responses && responses.length > 0) {
        const endTime = new Date().getTime();
        console.log(`Analysis took %c ${((endTime - startTime) / 1000).toFixed(1)} seconds `, 'font-weight: bold; background: #0c0; color: white;')
        let analysisResult: Record<string, unknown> = {};
        if (customRequests) {
          customRequests.forEach((request, index) => {
            analysisResult[request.type] = responses[index];
          });
        } else {
          analysisResult = responses[0];
        }
        dispatch({
          type: 'setComparisonCaseAtIndex',
          index: caseIndex,
          value: {
            ...comparisonCase,
            id: comparisonCase?.id || generateUniqueIntId(),
            isUnsaved: true,
            data: {
              analysisResult,
              inputValues: !customRequests ? getInputValuesRecordFromInputStates(inputStates) : null,
              customData,
            }
          }
        });
      }
      trigger('afterRunCase');
    } catch (err) {
      console.log('error!', err);
      setError(err.message);
    }
  }

  return runCaseAtIndex;
}



export const ComparableResultsModule: React.FC<{
  moduleData: ComparableResultsModuleProps
}> = ({
  moduleData,
  children,
}): JSX.Element => {

    const initialState: ModuleStateProps = {
      isAnyColumnFullscreened: false,
      numChartCols: 1,
      comparisonCases: [getEmptyComparisonCase()],
      caseIdsActiveInComparisonView: [],
      isComparisonMode: false,
      isFocusLinkActive: true,
      allowComparisons: moduleData.allowComparisons,
      maxComparisonCases: maxComparisonResultCols,
      maxCasesPerBatch: maxCasesPerBatch,
      allowChartTiling: moduleData.allowChartTiling,
      headerTitle: moduleData.title || getHeaderTitle(location?.pathname || ""),
      apiPath: moduleData.apiPath,
      chartControlAllocation: 'group',
      type: moduleData.subModules?.[0]?.type ?? moduleData.type,
    }

    interface ComparisonResultActionValue {
      id: number | null;
      index: number;
    }

    const processedInitialState = trackCaseIdsInComparison(initialState)

    const [state, dispatch] = React.useReducer(reducer, processedInitialState);

    const { savedCases } = useSavedCases()
    const { savedBatches } = useSavedBatches()
    const { demoCases, demoBatches } = useDemoContent()

    // const querystringToRecord = (querystring: string): Record<string, string> => {
    //   const querystringParts = querystring.split('&');
    //   const querystringRecord: Record<string, string> = {};
    //   querystringParts.forEach(part => {
    //     const [key, value] = part.split('=');
    //     querystringRecord[key] = value;
    //   });
    //   return querystringRecord;
    // }

    const performQueryStringActions = () => {
      const queryString = location.search?.replace('?', '');
      if (queryString) {
        const [mainParams, additionalParams] = queryString.split('&')
        const [action, value] = mainParams.split('=');
        if (typeof action === 'undefined' || typeof value === 'undefined') {
          return;
        }
        let ids;
        switch (action) {
          case 'loadCaseIds':
            ids = value.split(',');
            ids.forEach((id, index) => {
              if (index > 0) {
                dispatch({ type: 'appendEmptyComparisonCase' })
              }
              dispatch({ type: 'setComparisonCaseIdAtIndex', value: parseInt(id), index, dispatch });
            })
            // handle subModule for Industry (cement, steel, etc.)
            if (additionalParams) {
              const [param, value] = additionalParams.split('=');
              if (param === 'module') {
                console.log('module', value)
                dispatch({ type: 'setModuleType', value })
              }
            }
            dispatch({type: 'setCaseIdsActiveInComparisonView', value: ids.map(id => parseInt(id)) })
            break;
          case 'loadBatchId':
            const batchId = parseInt(value)
            let isDemoBatch = false
            if (additionalParams) {
              const [param, value] = additionalParams.split('=');
              if (param === 'demo' && value === "true") {
                isDemoBatch = true
              }
            }
            const batchToLoad = (isDemoBatch ? demoBatches : savedBatches).find(batch => batch.id === batchId)
            if (batchToLoad) {
              dispatch({ type: 'loadBatch', value: batchToLoad, dispatch });
            }
            break;
          case 'duplicateCaseIds':
            ids = value.split(',');
            ids.forEach((id, index) => {
              if (index > 0) {
                dispatch({ type: 'appendEmptyComparisonCase' })
              }

              getSavedCaseDetail(Number(id)).then(data => {
                const savedCase = data as SavedCaseInBackendDB
                dispatch({ type: 'setComparisonCaseAtIndex', value: {
                  id: generateUniqueIntId(),
                  data: {
                    inputValues: savedCase.inputs.inputValues,
                  }
                }, index, dispatch });
              })
            })
            break;
          default:
            break;
        }
        var cleanURI = location.protocol + "//" + location.host + location.pathname;
        window.history.replaceState({}, document.title, cleanURI);
      }
    }
    
    // TODO handle loading of focusedInputs here? (search where else in the code it was handled previously, when loading Dexie saved cases)

    React.useEffect(() => {
      performQueryStringActions()
    }, [])

    // check to see if we accidentally loaded a case that's not included in the current batch - if so, clear the saved batch ID
    const savedCaseIds = state.comparisonCases.map(c => c.savedCaseId).filter((id): id is number  => !!id)
    // const { savedBatches } = useSavedBatches()
    const currentSavedBatch = savedBatches.find(batch => batch.id === state.savedBatch?.id)
    React.useEffect(() => {
      if (currentSavedBatch) {
        if (savedCaseIds.some(id => !currentSavedBatch.cases.includes(id))) {
          dispatch({ type: 'setBatch', value: undefined })
        }
      }
    }, [currentSavedBatch, JSON.stringify(savedCaseIds), JSON.stringify(state.savedBatch)])

    const isFocusLinkingDisabled = useAtomValue(isFocusLinkingDisabledAtom)

    // sync isFocusLinkActive state with isFocusLinkingDisabled atom (which will only ever be true if different paths are loaded in Paths module)
    React.useEffect(() => {
      if (isFocusLinkingDisabled && state.isFocusLinkActive) {
        dispatch({ type: 'setIsFocusLinkActive', value: false })
      } else if (!isFocusLinkingDisabled && !state.isFocusLinkActive) {
        dispatch({ type: 'setIsFocusLinkActive', value: true })
      }
    }, [isFocusLinkingDisabled])

    // if (process.env.NODE_ENV === 'development') {
    //   console.log(JSON.stringify(state.comparisonCases))
    // }

    // console.log(state)

    return (
      <ModuleStateContext.Provider value={state}>
        <ModuleDispatchContext.Provider value={dispatch}>
          <SEO title={state.headerTitle || ''} />
          {children}
        </ModuleDispatchContext.Provider>
      </ModuleStateContext.Provider>
    )
  }

export const ComparisonRow = ({
  sidebar,
  content,
  className,
  style,
  columnizeContent = true,
}: {
  sidebar?: JSX.Element,
  content?: JSX.Element[],
  className?: string,
  style?: React.CSSProperties,
  columnizeContent?: boolean,
}) => {
  const { isComparisonMode, comparisonCases } = React.useContext(ModuleStateContext);
  const numCols = columnizeContent ? (comparisonCases?.length || 1) : 1;
  return (
    <div
      className={`${isComparisonMode ? 'flex' : 'gutter-x'} ${className ? className : ''}`}
      style={style}
    >
      {isComparisonMode && sidebar &&
        <div className={isComparisonMode ? 'comparison-sidebar' : ''}>
          {sidebar}
        </div>
      }
      <div
        className={`${isComparisonMode ? 'comparison-main' : ''} ${!sidebar && 'ml-[-1px]'}`}
        style={{ gridTemplateColumns: `repeat(${content?.length}, minmax(0, 1fr))` }}
      >
        {content}
      </div>
    </div>
  )
}
