import * as React from "react"
import Dexie, { PromiseExtended, Table } from 'dexie'
import relationships from 'dexie-relationships'
import { useLiveQuery } from "dexie-react-hooks";
import * as Sentry from "@sentry/browser"
import { isBrowser } from '../utils';
import useAuth from "./useAuth"
import { atom, useAtom } from "jotai"
import { useQuery, useQueryClient } from "react-query";
import useClient from "./useClient";
import { maxCasesPerBatch } from "../utils/constants";
import { ModuleStateContext } from "../components/comparableResultsModule";
import { authenticatedClient } from "../utils/client";
import { customAlert } from "../components/customAlert";

const databaseCollections = [
  {
    name: 'settings',
    fieldsToIndex: `++id`,
  }
] as Array<{
  name: DatabaseCollectionName;
  fieldsToIndex: string;
}>;

export const db = new Dexie('SESAME_DB', { addons: [ relationships ] }) as Dexie & {
  [key: string]: Table;
};

// NOTE: Don’t declare all columns like in SQL. You only declare properties you want to index, that is properties you want to use in a where(…) query.

const stores = databaseCollections.reduce((acc, collection) => {
  acc[collection.name] = collection.fieldsToIndex;
  return acc;
}, {} as {[key: string]: any})

db.version(13).stores(stores)

const defaultSettings: UserSetting[] = [
  {
    id: 'expandInputAccordionsByDefault',
    label: 'Expand input groups by default',
    value: false,
  },
  {
    id: 'dynamicallyFetchModuleMetadata',
    label: 'Dynamically fetch module metadata (not recommended)',
    value: false,
    isAdvanced: true,
  },
  {
    id: 'isNonCommercialUser',
    label: 'I am a non-commercial user',
    hidden: true,
    value: false,
  },
  {
    id: 'hasDismissedWelcomeModal',
    label: 'Has dismissed welcome modal',
    hidden: true,
    value: false,
  },
]

if (typeof window === 'object') {
  window.clearSettings = () => {
    db.settings.clear()
  }
}

const initializeDefaultSettings = () => {
  defaultSettings.forEach(defaultSetting => {
    db.settings.add(defaultSetting).catch((e) => {
      // console.log(`Setting `)
    });
  });
}

const hasInitializedDBAtom = atom<boolean>(false)

export const useInitializeDB = () => {

  const { isAuthenticated } = useAuth()
  const [hasInitializedDB, setHasInitializedDB] = useAtom(hasInitializedDBAtom)

  React.useEffect(() => {
    if (isBrowser() && isAuthenticated && !hasInitializedDB) {
      initializeDefaultSettings()
      setHasInitializedDB(true)
    }
  }, [isAuthenticated])

  return hasInitializedDB
}

export const demoTag = ' (demo)'

export const useRefreshSavedCases = () => {
  const queryClient = useQueryClient()
  const refreshSavedCases = ({caseIds}: {caseIds?: Array<SavedCaseInBackendDB['id']>}) => {
    // invalidate saved cases query (this one doesn't contain inputs or outputs data, just name, createdAt, id, etc.)
    queryClient.invalidateQueries('savedCases')
    // invalidate individual saved case detail queries that were just updated on the backend
    caseIds?.forEach(caseId => queryClient.invalidateQueries(`savedCase-${caseId}`))
  }
  return refreshSavedCases;
}

export const useRefreshSavedBatches = () => {
  const queryClient = useQueryClient()
  const refreshSavedBatches = ({batchIds}: {batchIds?: Array<SavedBatchInBackendDB['id']>}) => {
    // invalidate saved cases query (this one doesn't contain inputs or outputs data, just name, createdAt, id, etc.)
    queryClient.invalidateQueries('savedBatches')
    // invalidate individual saved case detail queries that were just updated on the backend
    batchIds?.forEach(batchId => queryClient.invalidateQueries(`savedBatch-${batchId}`))
  }
  return refreshSavedBatches;
}

/**
 * This hook fetches the user's saved cases AND demo cases from the backend, and concats them together.
 * Demo cases will have "demo: true" property.
 * @param type 
 * @returns 
 */
export const useSavedCases = (type?: string) => {
  const { client } = useClient()
  const { data, status, isLoading } = useQuery('savedCases', () => {
    return client('/cases/')
  })
  const { demoCases } = useDemoContent()
  return { savedCases: (data?.results || []).concat(demoCases) as SavedCaseInBackendDB[], status, isLoading }
}

export const useSavedCase = (id: SavedCaseInBackendDB['id'] | undefined) => {
  const { savedCases, isLoading } = useSavedCases()
  return { savedCase: savedCases?.find(savedCase => savedCase.id === id), isLoading }
}

export const useSavedCaseDetail = (id: SavedCaseInBackendDB['id'] | undefined) => {
  const { client } = useClient()
  const { data, status, isLoading } = useQuery(`savedCase-${id}`, () => {
    if (id) {
      return client(`/cases/${id}`)
    }
  })
  return { savedCaseData: data as SavedCaseInBackendDB, status, isLoading }
}

export const getSavedCaseDetail = (id: SavedBatchInBackendDB['id']) => {
  return authenticatedClient(`/cases/${id}/`, {})
}

export const useSavedCaseField = (id: SavedCaseInBackendDB['id'] | undefined, field: keyof SavedCaseInBackendDB): any => {
  const { savedCaseData, status, isLoading } = useSavedCaseDetail(id)
  return { [field]: savedCaseData?.[field], status, isLoading }
}

export const useSavedBatches = (type?: string) => {
  const { client } = useClient()
  const { data, status, isLoading } = useQuery('savedBatches', () => {
    return client('/batches/')
  })
  const { demoBatches } = useDemoContent()
  return { savedBatches: (data?.results || []).concat(demoBatches) as SavedBatchInBackendDB[], status, isLoading }
}

export const useSavedBatchDetail = (id: SavedBatchInBackendDB['id'] | undefined) => {
  const { client } = useClient()
  const { data, status, isLoading } = useQuery(`savedBatch-${id}`, () => {
    if (id) {
      return client(`/batches/${id}`)
    }
  })
  return { savedBatchData: data as SavedBatchInBackendDB, status, isLoading }
}

export const useSavedBatchField = (id: SavedBatchInBackendDB['id'] | undefined, field: keyof SavedBatchInBackendDB): any => {
  const { savedBatchData, status, isLoading } = useSavedBatchDetail(id)
  return { [field]: savedBatchData?.[field], status, isLoading }
}

export const useDemoContent = () => {

  const { client } = useClient()

  // fetch demo cases
  const { data: demoCasesData } = useQuery('demoCases', () => {
    return client(`/cases/?demo=true`) // TODO fix when Scott's new endpoint is live
  })

  // fetch demo batches
  const { data: demoBatchesData } = useQuery('demoBatches', () => {
    return client(`/batches/?demo=true`)
  })

  const demoCases: SavedCaseInBackendDB[] = demoCasesData?.results ?? []
  const demoBatches: SavedBatchInBackendDB[] = demoBatchesData?.results ?? []

  return {
    demoCases,
    demoBatches,
  }
}

export const useAnalysisResultsForActiveCases = () => {

  const { comparisonCases } = React.useContext(ModuleStateContext)

  const analysisResults = new Array(maxCasesPerBatch).fill(null).map((o, index) => {

    const comparisonCase = comparisonCases?.[index]

    // attempt to get outputs from saved case (we always run this even if the case is not saved yet, to avoid triggering "diff number hooks on re-render" error)
  let { savedCaseData } = useSavedCaseDetail(comparisonCase?.savedCaseId)
    // let { outputs } = useSavedCaseField(comparisonCase?.savedCaseId, 'outputs')

    let outputs: any
    if (savedCaseData?.outputs) {
      outputs = savedCaseData.outputs
    } else {
      // if case is unsaved, use the outputs stored in the comparisonCase in moduleState
      if (comparisonCase?.isUnsaved) {
        outputs = comparisonCase?.data?.analysisResult
      }
    }

    if (!comparisonCase || !outputs) {
      return null;
    }
    return {
      ...outputs,
      id: comparisonCase?.id
    }
  });

  return analysisResults
}

/**
 * 
 * @param id Case id in backend
 * @param value The update object
 */
export const useUpdateCase = () => {

  const refreshSavedCases = useRefreshSavedCases()

  const updateCase = ({ 
    id,
    value
  }: {
    id: SavedCaseInBackendDB['id']
    value: Partial<SavedCaseInBackendDB>
  }) => {
    return authenticatedClient(`/cases/${id}/`, {
      method: 'PATCH',
      body: value
    }).then(response => {
      console.log(response)
      refreshSavedCases({caseIds: [id]})
      customAlert.success('Case updated')
    })
  }

  return updateCase
}


/**
 * 
 * @param id Case id in backend
 * @param value The update object
 */
export const useUpdateBatch = () => {

  const refreshSavedBatches = useRefreshSavedBatches()

  const updateBatch = ({ 
    id,
    value
  }: {
    id: SavedBatchInBackendDB['id']
    value: Partial<SavedBatchInBackendDB>
  }) => {
    return authenticatedClient(`/batches/${id}/`, {
      method: 'PATCH',
      body: value
    }).then(response => {
      console.log(response)
      refreshSavedBatches({batchIds: [id]})
      customAlert.success('Batch updated')
    })
  }

  return updateBatch
}

export const useVisibleAnalysisResults = () => {
  const { caseIdsActiveInComparisonView } = React.useContext(ModuleStateContext)
  const analysisResults = useAnalysisResultsForActiveCases()
  return caseIdsActiveInComparisonView?.map(caseId => {
    return analysisResults?.find(analysisResult => analysisResult?.id === caseId)
  }) ?? []
}

/**
 * Fires a custom prompt for inputting a name
 * @returns a promise that resolves to the inputted name
 */
export const promptName = async () => {
  return customAlert.prompt('Choose a name:')
}

// const doesSavedCaseNameExistForModule = async (name: string, moduleType: string) => {
//   const query: any = { name: name, type: moduleType }
//   const doesNameExistAlready = !!(await db.savedCases.get(query));
//   // console.log(savedCaseWithThisName);
//   return doesNameExistAlready;
//   // return typeof savedCaseWithThisName === 'object';
// }

export const getIncrementedCaseNamesForModule = async ({
  startingNumber = 1,
  startingName,
  moduleType,
  numCases,
  // namesArray,
}: {
  startingNumber?: number;
  startingName: string;
  moduleType: string;
  numCases: number;
}): Promise<string[]> => {

  let lastUsedCaseNumber = startingNumber;
  const validCaseNames: string[] = [];

  while (validCaseNames.length < numCases) {
    // while (await doesSavedCaseNameExistForModule(
    //   `${startingName} ${lastUsedCaseNumber}`,
    //   moduleType,
    // )) {
    //   lastUsedCaseNumber++;
    // }
    validCaseNames.push(`${startingName} ${lastUsedCaseNumber}`);
    lastUsedCaseNumber++;
  }

  return validCaseNames;
}