import * as React from "react"
import { useSavedCase, useSavedCaseField } from "../hooks/useDB"
import { getCaseNameFromComparisonCaseAtIndex, ModuleStateContext } from "./comparableResultsModule"
import { Loader } from "./styles"

export const SavedCaseName = ({
  comparisonIndex,
}: {
  comparisonIndex: number,
}) => {
  const { comparisonCases } = React.useContext(ModuleStateContext)
  const comparisonCase = comparisonCases?.[comparisonIndex]
  let { savedCase, isLoading } = useSavedCase(comparisonCase?.savedCaseId)
  if (!comparisonCase?.savedCaseId) {
    isLoading = false
  }

  const name = savedCase?.name ?? getCaseNameFromComparisonCaseAtIndex(comparisonCase, comparisonIndex)

  return (
    <>
      {isLoading
        ?
        <Loader size="small" />
        :
        <span className="overflow-hidden whitespace-nowrap overflow-ellipsis" title={name}>
          {name}
          {(!comparisonCase?.savedCaseId || comparisonCase?.isUnsaved) &&
            <span className="text-gray-400 ml-1">*</span>
          }
        </span>
      }
    </>
  )
}