import * as React from "react";
import { db, getIncrementedCaseNamesForModule, useSavedCaseDetail, useSavedCaseField } from "../hooks/useDB";
import { useEventListener } from "../hooks/useEventListener";
import { useRunAndSaveCaseEventListeners } from "../hooks/useRunAndSaveCaseEventListeners";
import useUserInputs, { getInputValuesRecordFromInputStates } from "../hooks/useUserInputs";
import { trigger } from "../utils/events";
import { ComparisonRow, ModuleDispatchContext, ModuleStateContext, useRunCaseAtIndex, useSaveBatch, useSaveCaseAtIndex } from "./comparableResultsModule";
import { customAlert } from "./customAlert";
import { SavedItemMenu } from "./savedItemMenu";
import UserInputs from "./userInputs";
import { SavedBatchControls } from "./savedBatchControls";
import client from "../utils/client";
import { useSetting, useSettingValue } from "../hooks/useSettings";
import { Loader } from "./styles";
import { useMakeSureSomeInputsAreVisibleAtIndex } from "../hooks/useMakeSureSomeInputsAreVisibleAtIndex";
import { BatchCaseList } from "./batchCaseList";
import { maxCasesPerBatch } from "../utils/constants";
import { useLoadFocusStatesFromSavedCase } from "../hooks/useLoadFocusStatesFromSavedCase";

export const ComparisonInputHandlerContext = React.createContext<Partial<InputHandler[]>>([]);

export const ComparisonInputHandler = ({
  moduleMetadata,
  apiPathOverride,
  extraContentByCase,
  source = undefined,
}: {
  moduleMetadata: BasicModuleMetadata;
  apiPathOverride?: string;
  extraContentByCase?: JSX.Element[];
  source?: undefined | string;
}): JSX.Element => {

  const moduleState = React.useContext(ModuleStateContext) as ModuleStateProps;
  const { comparisonCases, caseIdsActiveInComparisonView, isComparisonMode, maxComparisonCases, type, apiPath } = moduleState;
  const [hasLoadedDynamicModuleMetadata, setHasLoadedDynamicModuleMetadata] = React.useState(false)
  const saveBatch = useSaveBatch()

  const caseIdsHidden = comparisonCases.map(c => c.id).filter(id => !caseIdsActiveInComparisonView.includes(id))

  const dispatch = React.useContext(ModuleDispatchContext);

  const apiEndpoint = `${apiPathOverride || apiPath}/analysis`;

  if (!apiEndpoint) {
    throw new Error('No API endpoint provided in module definition')
  }

  // allow for dynamic metadata fetching instead of static metadata built during frontend build (for accelerating backend user_input changes in modules)
  const dynamicallyFetchModuleMetadata = useSettingValue('dynamicallyFetchModuleMetadata');
  const [metadata, setMetadata] = React.useState<Partial<BasicModuleMetadata>>(dynamicallyFetchModuleMetadata ? { user_inputs: [], hash: '' } : moduleMetadata);

  const fetchMetadata = () => {
    client(`${apiPathOverride || apiPath}/metadata`).then(response => {
      const transformedMetadata = {
        user_inputs: (response as BasicModuleMetadata).user_inputs?.map(mapUserInputs),
        hash: (response as BasicModuleMetadata).hash,
      }
      setMetadata(transformedMetadata);
      setHasLoadedDynamicModuleMetadata(true);
    });
  }

  React.useEffect(() => {
    if (dynamicallyFetchModuleMetadata && !hasLoadedDynamicModuleMetadata) {
      fetchMetadata()
    }
  }, [metadata, dynamicallyFetchModuleMetadata, hasLoadedDynamicModuleMetadata])

  React.useEffect(() => {
    if (dynamicallyFetchModuleMetadata) {
      fetchMetadata();
    } else {
      setMetadata(moduleMetadata)
    }
  }, [apiPathOverride])

  /**
   * Note on the following:
   * This is a high-technical-debt implementation of user input state tracking.
   * This should be migrated at some point to a different state management system,
   * so the info is available anywhere in the app. It's currently local to ComparisonInputHandler
   * to avoid performance issues if it were to be placed in ModuleStateContext (which would
   * cause a massive cascade of re-renders anytime any input value changed, conditional input options were fetched, etc.).
   * Moving to a more granular updating state tracking library like Zustand might be a good idea, 
   * where the input states can be globally available to the app, but won't cause unnecessary re-renders.
   * Jotai might be another option, but could get messy if that's used alongside ModuleStateContext (useContext) - 
   * should really pick one system and use that for everything.
   */
  const arrayOfComparisonCaseInputHandlers = new Array(maxCasesPerBatch).fill(null).map((o, comparisonIndex) => {

    const savedCaseId = comparisonCases?.[comparisonIndex]?.savedCaseId
    const comparisonCase = comparisonCases?.[comparisonIndex]

    const { inputs } = useSavedCaseField(savedCaseId, 'inputs')

    const [inputStates, setInput, isValid, setSourceOrAnalysis, flattenedUserInputs, setInputError] = useUserInputs(
      metadata.user_inputs,
      source,
      apiPathOverride || apiPath,
      inputs?.inputValues || comparisonCase?.data?.inputValues // the saved inputValues from the backend DB saved case
    );

    return {
      inputStates,
      setInput,
      isValid,
      setInputError,
    };
  });

  const attemptToRunAllCases = () => {
    const caseIndexesWithValidInputs: number[] = [];
    // debugger
    arrayOfComparisonCaseInputHandlers.forEach((inputHandler, comparisonIndex) => {
      const areInputsValid = inputHandler.isValid;
      const isThisCaseActive = comparisonCases?.length && comparisonIndex < comparisonCases?.length;
      const isThisCaseAlreadyRun = !!comparisonCases?.[comparisonIndex]?.data?.analysisResult || comparisonCases?.[comparisonIndex]?.savedCaseId;
      if (areInputsValid && isThisCaseActive && !isThisCaseAlreadyRun) {
        caseIndexesWithValidInputs.push(comparisonIndex);
      }
    });
    if (!caseIndexesWithValidInputs.length) {
      customAlert({ message: 'No cases to run. All cases either have already been run, or have invalid inputs.' })
    }
    caseIndexesWithValidInputs.forEach(caseIndex => {
      console.log('runCase at index:', caseIndex)
      trigger('runCase', { caseIndex: caseIndex })
    })
  }

  const handleSaveBatch = () => {
    saveBatch({
      moduleType: type,
      comparisonCases,
      unsavedCaseData: comparisonCases.map((comparisonCase, caseIndex) => {
        if (comparisonCase.isUnsaved) {
          return {
            inputStates: arrayOfComparisonCaseInputHandlers[caseIndex].inputStates,
            moduleVersion: metadata.version,
            hash: metadata.hash,
          }
        }
      }),
      dispatch,
    })
  }

  useEventListener('attemptToRunAllCases', attemptToRunAllCases)
  useEventListener('saveBatch', handleSaveBatch);

  if (!metadata) {
    return <Loader />
  }

  return (
    <ComparisonInputHandlerContext.Provider value={arrayOfComparisonCaseInputHandlers}>
      <ComparisonRow
        sidebar={
          <>
            <SavedBatchControls />
            <BatchCaseList />
          </>
        }
        
        content={caseIdsActiveInComparisonView?.map((caseId, comparisonIndex) => {
          const comparisonCase = comparisonCases.find(c => c.id === caseId)
          const isInComparison = caseIdsActiveInComparisonView.includes(caseId)
          if (!comparisonCase) return <></>
          const caseIndex = comparisonCases.indexOf(comparisonCase)
          // if (!comparisonCase) return <></>
          return (
            <div
              key={comparisonCase.id} // this key is crucial to making sure the input values stay properly tracked/saved when we switch visible cases in a comparison col
              id={`inputs--case-${comparisonIndex}`}
              className={comparisonCase.isFocusModeActive && isComparisonMode ? 'pt-2' : ''}
            >
              <InputHandler
                caseIndex={caseIndex}
                metadata={metadata}
                inputHandler={arrayOfComparisonCaseInputHandlers[caseIndex]}
                apiEndpoint={apiEndpoint}
              />
              {extraContentByCase &&
                extraContentByCase[caseIndex]
              }
            </div>
          )
        })}
      />
    </ComparisonInputHandlerContext.Provider>
  )
}

export const InputHandler = ({
  caseIndex,
  metadata,
  apiEndpoint,
  inputHandler,
}: {
  caseIndex: number,
  metadata: BasicModuleMetadata,
  apiEndpoint: string,
  inputHandler: InputHandler,
}): JSX.Element => {
  const { comparisonCases, type: moduleType, caseIdsActiveInComparisonView } = React.useContext(ModuleStateContext);
  const dispatch = React.useContext(ModuleDispatchContext);
  const comparisonCase = caseIndex >= 0 ? comparisonCases?.[caseIndex] : null;
  const { savedCaseData, status, isLoading } = useSavedCaseDetail(comparisonCase?.savedCaseId)
  const focusedInputs = comparisonCase?.focusedInputs;
  const { inputStates, setInput, isValid, setInputError } = inputHandler;

  const colIndex = caseIdsActiveInComparisonView?.indexOf(comparisonCase?.id)

  const [error, setError] = React.useState<string>('');

  // console.log(status, isLoading, savedCaseData)

  const [inputGroupOpenStates, setInputGroupOpenStates] = React.useState<Record<string, boolean>>(comparisonCase?.data?.inputGroupOpenStates || {})

  const expandInputAccordionsByDefault = useSettingValue('expandInputAccordionsByDefault');

  const toggleInputGroupOpenState = (name: string) => {
    setInputGroupOpenStates(prevStates => {
      return {
        ...prevStates,
        [name]: !(prevStates[name] ?? expandInputAccordionsByDefault),
      }
    })
  }

  useMakeSureSomeInputsAreVisibleAtIndex({
    caseIndex,
    inputStates,
  })

  useLoadFocusStatesFromSavedCase(savedCaseData, caseIndex)

  // we should just do this in InputHandler. if it's an active displayed case, check if it has savedCaseId, and demo=true, and has focusedInputs... if so, dispatch relevant actions

  // show error dialog on analysis running error
  React.useEffect(() => {
    if (error) {
      customAlert({ message: error, type: 'error' })
    }
    setError('')
  }, [error])

  const runCaseAtIndex = useRunCaseAtIndex()
  const saveCaseAtIndex = useSaveCaseAtIndex()

  const handleRun = () => {

    console.log('attempting to run with index:', caseIndex)

    runCaseAtIndex({
      caseIndex,
      comparisonCase,
      apiEndpoint,
      inputStates,
      isValid: isValid && !error,
      setError,
    })
  }

  const handleSave = () => {
    saveCaseAtIndex({
      // name,
      comparisonCases: comparisonCases,
      index: caseIndex,
      data: {
        inputStates, // we have to send inputStates here b/c they aren't tracked in moduleState yet, only in each module itself.
        // hash: metadata.hash,
        moduleVersion: metadata.version,
      },
      moduleType: moduleType,
      // dispatch: action.dispatch,
    })
  }

  const handleDuplicate = (): void => {
    console.log('duplicting with inputgroupopenstates:', inputGroupOpenStates)
    dispatch({
      type: 'duplicateCaseAtIndexWithData',
      index: caseIndex,
      value: {
        inputValues: getInputValuesRecordFromInputStates(inputStates),
        inputGroupOpenStates: inputGroupOpenStates,
      },
    })
  }

  useRunAndSaveCaseEventListeners(handleRun, handleSave, caseIndex, handleDuplicate);

  return (
    <>
      <UserInputs
        userInputs={metadata.user_inputs}
        comparisonIndex={caseIndex}
        inputStates={inputStates}
        inputGroupOpenStates={inputGroupOpenStates}
        toggleInputGroupOpenState={toggleInputGroupOpenState}
        setInput={(name, value, opts) => {
          setError("");
          if (!opts?.dontClearComparisonCase) {
            //
            if (comparisonCases)
              dispatch({ type: 'clearCaseAtComparisonColumnIndex', index: colIndex })
          }
          setInput(name, value, opts);
        }}
        setInputError={setInputError}
      />
      {/* {error && (
        <div className="text-red-700">
          <br />
          {error}
        </div>
      )} */}
    </>
  )
}

const mapConditionals = (conditionals) => {
  // if the second arg in a conditional is not an array, return it in an array
  return conditionals.length > 0
    ? conditionals.map(({ args, ...rest }) => ({
      ...rest,
      args: [[args[0]], Array.isArray(args[1]) ? args[1] : [args[1]]],
    }))
    : [];
};

const mapUserInputs = (u) => ({
  ...u,
  options: u.options
    ? u.options.map(({ value, conditionals }) => ({
      // toString() all options (string | number)...
      // since the GraphQL spec does not support union scalar types:
      // https://github.com/graphql/graphql-spec/issues/215
      value: value.toString(),
      conditionals: mapConditionals(conditionals),
    }))
    : undefined,
  conditionals: mapConditionals(u.conditionals),
});
