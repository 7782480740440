import { Menu, Transition } from '@headlessui/react'
import * as React from 'react'
import { Fragment, useEffect, useRef, useState } from 'react'
import { CheckIcon, ChevronDownIcon, EyeIcon, EyeOffIcon, PlusCircleIcon, XIcon } from '@heroicons/react/solid'
import { getCaseNameFromComparisonCaseAtIndex, ModuleDispatchContext, ModuleStateContext } from './comparableResultsModule'
import { useLiveQuery } from 'dexie-react-hooks'
import { db, useSavedCaseDetail, useSavedCaseField, useSavedCases, useSavedBatches, useSavedBatchField, useRefreshSavedCases, useRefreshSavedBatches, useUpdateCase, useUpdateBatch, promptName } from '../hooks/useDB'
import { capitalize, generateUniqueIntId, unique } from '../utils'
import { Button, Label, Loader } from './styles'
import { Link, navigate } from 'gatsby'
import { customAlert } from './customAlert'
import { Tooltip } from './tooltip'
import { SavedCaseName } from './savedCaseName'
import { useDeleteSavedItemIds } from '../hooks/useDeleteItemIds'
import useClient from '../hooks/useClient'
import { comparableResultsModules } from '../pages/app'

export const SavedItemMenu = ({
  comparisonIndex,
  // activeItem,
  // activeItemId,
  // activeItemName,
  collectionName,
  // itemTitle,
  // isUnsaved = false,
  placeholder,
  layout = 'row',
  maxHeight = '80vh', // in any CSS units, e.g. '50vh' or '400px'
  displayActiveCasesInBatch,
  showLabel = true,
  createCaseMethod = 'overwrite',
}: {
  comparisonIndex?: number;
  // activeItem?: ComparisonCase | ComparisonCaseBatch;
  // activeItemId?: number;
  // activeItemName?: string;
  collectionName: SavedItemCollectionName;
  // itemTitle: string;
  isUnsaved?: boolean;
  placeholder?: string;
  layout?: 'row' | 'column';
  maxHeight?: string;
  displayActiveCasesInBatch?: boolean;
  showLabel?: boolean;
  createCaseMethod?: 'overwrite' | 'append';
}) => {

  const { type: moduleType, isComparisonMode, comparisonCases, savedBatch, caseIdsActiveInComparisonView } = React.useContext(ModuleStateContext) as ModuleStateProps;
  const dispatch = React.useContext(ModuleDispatchContext)
  const deleteSavedItemIds = useDeleteSavedItemIds(collectionName)
  // const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const { savedCases } = useSavedCases()
  const { savedBatches } = useSavedBatches()

  // const moduleInfo = comparableResultsModules.find(c => c.type === moduleType)

  const buttonRef = React.useRef<HTMLButtonElement>();

  let itemTitle: string = ''
  let activeItem: SavedCaseInBackendDB | SavedBatchInBackendDB | undefined
  let activeItemId: number | undefined
  let activeItemName: string | undefined
  let isNameLoading = false
  let isUnsaved: boolean

  // preferentially use sub module type (e.g. cement) if available, otherwise main module type
  // this is for Industry, which has "submodules" (cement, etc.) which are actually just modules, while Industry is actually more of a "module group", but is defined as a module in app.tsx because it's acting as its own module page

  // console.log(moduleType)
  // console.log(savedCases)

  // const isLastResultColumn = index === (numCasesInComparison - 1);

  let savedItems: Array<SavedCaseInBackendDB | SavedBatchInBackendDB> = []

  // OLD WRONG INFO: for saved cases, the activeItemId refers to the ID of the case in ModuleStateContext state, not its actual savedCaseId in the backend.
  // OLD WRONG INFO: for saved batches, the activeItemId refers to its actual savedBatchId in the backend, since we don't need to assign a separate ID to each batch, since we only ever have one active one
  if (collectionName === 'savedCases') {
    itemTitle = 'Case'
    const activeCaseIds = comparisonCases.map(c => c.id)
    savedItems = savedCases.filter(savedCase => (
      !activeCaseIds.includes(savedCase.id)
      &&
      savedCase.module === moduleType
      &&
      !savedCase.demo
    )) // don't display already-active cases in saved case menu
    if (typeof comparisonIndex === 'number') {
      activeItemId = caseIdsActiveInComparisonView?.[comparisonIndex] // this is the ID of the case in moduleState, but not the savedCaseId from the backend
    }
    const comparisonCase = comparisonCases?.find(c => c.id === activeItemId) // OLD WRONG INFO: this is the actual id of the savedCase in the backend
    activeItem = !comparisonCase?.isUnsaved ? savedCases.find(s => s.id === comparisonCase?.savedCaseId) : undefined
    // const { name: activeCaseName, isLoading } = useSavedCaseField(activeItem ? activeItem.id, 'name')
    activeItemName = activeItem?.name
    // console.log(activeCaseName)
    // isNameLoading = isLoading
  } else if (collectionName === 'savedBatches') {
    itemTitle = 'Batch'
    savedItems = savedBatches.filter(savedBatch => savedBatch.module === moduleType && !savedBatch.demo)
    activeItemId = savedBatch?.id // this is the actual saved batch ID, stored in moduleState
    activeItem = savedBatches.find(s => s.id === activeItemId)
    activeItemName = activeItem?.name ?? 'New Batch'
    // const { name: activeBatchName, isLoading } = useSavedBatchField(activeItemId, 'name')
    // activeItemName = activeBatchName
    // isNameLoading = isLoading
  }
  isUnsaved = !activeItem // if we have an extant activeItem, that means a saved case or batch object from the backend DB, meaning it's NOT unsaved

  // const activeCaseName = isNameLoading ? '...' : active ?? getCaseNameFromComparisonCaseAtIndex(comparisonCases?.[0], 0)

  // const currentItemName = savedItems?.find(savedItem => savedItem.id === comparisonItemId)?.name || getItemNameFromComparisonItemAtIndex(comparisonItems?.[comparisonIndex], comparisonIndex);
  // const currentItemName = getCurrentItemName();

  const getItemNameById = (id: number) => {
    return savedItems?.find(savedItem => savedItem.id === id)?.name;
  }

  const isActiveItemReadOnly = activeItem?.demo;

  const menuItemClasses = "py-1 px-4"
  const hoverableMenuItemClasses = `${menuItemClasses} h-[2.25rem] flex items-center cursor-pointer hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900`;
  const disabledHoverableMenuItemClasses = `hover:!bg-transparent !cursor-default !font-bold`

  const closeMenu = () => {
    buttonRef?.current?.click();
  }

  const [checkedIds, setCheckedIds] = React.useState<number[]>([]);

  useEffect(() => {
    setCheckedIds([]);
  }, [JSON.stringify(savedItems?.map(savedItem => savedItem.id))])

  const delaySetCheckedIds = () => {
    setTimeout(() => {
      setCheckedIds([]);
    }, 1);
  }

  const toggleItemIdChecked = (id: number) => {
    const isItemIdAlreadyChecked = checkedIds.some(checkedId => checkedId === id);
    if (isItemIdAlreadyChecked) {
      setCheckedIds([...checkedIds].filter(checkedId => checkedId !== id));
    } else {
      setCheckedIds(unique([...checkedIds].concat(id)) || []);
    }
  }

  return (
    <div className={`flex ${layout === 'column' ? 'flex-col space-y-1' : 'items-center mr-3'} min-w-0 max-w-full`}>
      {showLabel &&
        <Label className={`mr-2 text-gray-500 ${isComparisonMode ? '' : 'ml-6'}`}>{itemTitle}:</Label>
      }
      <div className="flex items-center">
        <div className="text-left min-w-0 flex-1">
          <Menu as="div" className="relative w-full text-left" id={`saved-${itemTitle.toLowerCase()}-menu-${comparisonIndex}`}>
            {({ open }) => {
              if (!open) {
                if (checkedIds.length > 0) {
                  delaySetCheckedIds();
                }
              }
              const caseIndexOfActiveComparisonCol = comparisonCases.map(c => c.id).indexOf(caseIdsActiveInComparisonView[comparisonIndex])
              return (
                <>
                  <div>
                    <Menu.Button ref={buttonRef} className="flex flex-row w-full px-4 py-1 bg-white font-medium text-gray-700 border border-gray-300 rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                      <span style={{ textOverflow: 'ellipsis' }} className={`overflow-hidden whitespace-nowrap saved-${itemTitle.toLowerCase()}-name`}>
                        {placeholder ?
                          placeholder
                          :
                          <>
                            {collectionName === 'savedCases' && typeof comparisonIndex === 'number' &&
                              // this is not right... we need to 
                              <SavedCaseName comparisonIndex={caseIndexOfActiveComparisonCol} />
                            }
                            {collectionName === 'savedBatches' &&
                              <>
                                {activeItemName}
                              </>
                            }
                          </>
                        }
                      </span>
                      <ChevronDownIcon
                        className="w-5 h-5 ml-2 -mr-2 mt-[1px] text-violet-200 hover:text-violet-100"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-100"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-100"
                  >
                    <Menu.Items
                      className={`saved-${itemTitle.toLowerCase()}-dropdown z-30 absolute flex flex-col left-0 w-80 mt-1 origin-top-right bg-white divide-y rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
                      style={{ maxHeight: maxHeight }}
                    >

                      {/* <div className='flex flex-col divide-x'> */}

                      {/* Active Cases in Batch List */}
                      {displayActiveCasesInBatch &&
                        // <div className='divide-y'>

                        <>
                          <div className={`${menuItemClasses} py-2 bg-gray-100 font-bold rounded-t flex items-center`}>
                            Active Cases in Batch
                          </div>
                          <div className=''>
                            {comparisonCases.map((comparisonCase, index) => {
                              const isCaseVisibleInAnyCol = caseIdsActiveInComparisonView.includes(comparisonCase.id)
                              return (
                                <div
                                  className={`${isCaseVisibleInAnyCol ? `${menuItemClasses} font-bold opacity-40` : hoverableMenuItemClasses}`}
                                  onClick={(e) => {
                                    if (!isCaseVisibleInAnyCol) {
                                      dispatch({ type: 'setActiveComparisonCaseIdAtIndex', value: comparisonCase.id, index: comparisonIndex })
                                      closeMenu()
                                    }
                                  }}
                                >
                                  {/* {true ? // change this to whether this case is active in comparison right now
                                      <EyeIcon className='w-5 h-5 text-gray-700 mr-2' /> 
                                      :
                                      <EyeOffIcon className='w-5 h-5 text-gray-400 mr-2' /> 
                                    } */}
                                  <SavedCaseName comparisonIndex={index} />
                                  {/* <RemoveCaseFromBatchButton caseIndex={index} isUnsaved={comparisonCase.isUnsaved} /> */}
                                </div>
                              )
                            })}
                          </div>
                        </>
                        // </div>
                      }

                      {/* Saved Case/Batch List */}

                      {/* Create new case menu item */}
                      {collectionName === 'savedCases' &&
                        <div
                          className={`${hoverableMenuItemClasses} py-2`}
                          onClick={() => {
                            if (checkedIds.length === 0) {

                              if (createCaseMethod === 'append') {
                                dispatch({ type: 'appendEmptyComparisonCase' })//, index: comparisonIndex})
                                closeMenu()
                              } else {
                                customAlert({
                                  type: 'confirm',
                                  message: 'Replace the current case in this column?',
                                  confirmButtonText: 'Replace',
                                  cancelButtonText: 'Keep',
                                  onConfirm: () => {
                                    // replace the active case in this col, and at this index in comparisonCases, with a new case
                                    dispatch({ type: 'resetComparisonCaseAtIndex', index: comparisonIndex })
                                    closeMenu()
                                    // dispatch({type: 'setComparisonCaseIdAtIndex', index: comparisonIndex, value: savedItem.id, dispatch: dispatch })
                                    // dispatch({type: 'setActiveComparisonCaseIdAtIndex', index: comparisonIndex, value: savedItem.id})
                                    // dispatch({type: 'setComparisonCaseIdAtIndex', index, value: comparisonCase.id})
                                  },
                                  onCancel: () => {
                                    // append the new case
                                    dispatch({ type: 'appendEmptyComparisonCase' })//, index: comparisonIndex})
                                  }
                                })
                              }

                              
                            }
                          }}
                        >
                          <PlusCircleIcon className='w-6 h-6 ml-[-2px] text-green-600 mr-2' /> Create new {itemTitle.toLowerCase()}
                        </div>
                      }

                      <div className={`px-4 py-2 bg-gray-100 font-bold`}>
                      {displayActiveCasesInBatch
                          ?
                          'Add Saved Case to Batch'
                          :
                          <>
                            Saved {collectionName === 'savedCases' ? 'Cases' : 'Batches'}
                          </>
                        }
                      </div>

                      {/* SAVED CASE LIST */}
                      <div className="overflow-y-auto flex-auto">
                        {savedItems?.length === 0 &&
                          <div className={`${menuItemClasses} !text-gray-400`}>No saved {collectionName === 'savedCases' ? 'cases' : 'batches'} yet</div>
                        }
                        {savedItems?.map((savedItem, index) => {
                          // if this item is already in the batch, let's bold and disable it
                          // const isAlreadyInBatch = collectionName === 'savedCases' && comparisonCases.map(c => c.id).includes(savedItem.id)
                          return (
                            <div
                              key={savedItem.id}
                              className={`saved-${itemTitle.toLowerCase()}-menu-item ${hoverableMenuItemClasses}`}
                              onClick={() => {
                                if (checkedIds.length === 0) {
                                  if (collectionName === 'savedCases') {
                                    const caseIndexForLoadedCase = typeof comparisonIndex === 'number' ? comparisonIndex : comparisonCases.length
                                    // if we're in comparison mode, load the case and view it in this column, but preserve the case that was there before
                                    // otherwise, in singular mode, just replace the case and don't preserve it
                                    if (isComparisonMode) {
                                      dispatch({ type: 'setComparisonCaseIdAtIndex', index: comparisonCases.length, value: savedItem.id })
                                      if (comparisonIndex !== undefined) {
                                        dispatch({ type: 'setActiveComparisonCaseIdAtIndex', index: comparisonIndex, value: savedItem.id })
                                      }
                                    } else {
                                      dispatch({ type: 'setComparisonCaseIdAtIndex', index: caseIndexForLoadedCase, value: savedItem.id })
                                    }
                                  } else if (collectionName === 'savedBatches') {
                                    // const shouldLoadBatch = confirm('');
                                    customAlert({
                                      title: 'Warning',
                                      message: 'Loading a batch will clear all active cases. Proceed?',
                                      type: 'confirm',
                                      onConfirm: () => {
                                        console.log(savedItem)
                                        dispatch({ type: 'loadBatch', value: savedItem, dispatch: dispatch })
                                      }
                                    });
                                  }
                                  closeMenu();
                                } else {
                                  toggleItemIdChecked(savedItem.id);
                                }
                              }}
                            >
                              <input
                                type="checkbox"
                                className="rounded border-gray-300 mr-2 h-5 w-5"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  toggleItemIdChecked(savedItem.id);
                                }}
                                onChange={(e) => { }} // to squelch react error msg about not having onchange
                                checked={checkedIds.some(id => id === savedItem.id)}
                              />
                              <a className={`${savedItem.id === activeItemId ? 'font-bold' : ''}`} key={index}>{savedItem.name}</a>
                              <svg
                                className="flex-shrink-0 cursor-pointer ml-auto h-7 w-7 p-1 text-gray-400 hover:text-red-500 group-focus:text-gray-500"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  customAlert({
                                    message: `Delete "${getItemNameById(savedItem.id)}"?`,
                                    type: 'confirm',
                                    onConfirm: () => {
                                      deleteSavedItemIds([savedItem.id]);
                                      closeMenu();
                                    }
                                  });
                                }}
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </div>
                          )
                        })}
                      </div>
                      {savedItems?.length > 0 &&
                        <div className={menuItemClasses}>
                          {checkedIds.length === 0 ?
                            <Button
                              size="small"
                              className="my-1"
                              onClick={() => {
                                setCheckedIds(savedItems.map(savedItem => savedItem.id));
                              }}
                            >Select all</Button>
                            :
                            <Button
                              color="red"
                              size="small"
                              className="my-1"
                              onClick={() => {
                                const shouldDelete = confirm(`Really delete ${checkedIds.length} saved item(s)?`)
                                if (shouldDelete) {
                                  deleteSavedItemIds(checkedIds);
                                  closeMenu();
                                }
                              }}
                            >Delete selected</Button>
                          }
                        </div>
                      }
                      <div
                        onClick={(e) => {
                          customAlert({
                            type: 'confirm',
                            message: 'Are you sure you want to leave this page? All unsaved data will be lost.',
                            onConfirm: () => {
                              navigate(`/app/saved?moduleType=${type}`)
                            }
                          })
                        }}
                        className={hoverableMenuItemClasses}
                      >
                        More actions...
                      </div>

                    </Menu.Items>
                  </Transition>
                </>
              )
            }}
          </Menu>
        </div>
        {!!activeItemId && !isUnsaved && !isActiveItemReadOnly &&
          <EditItemNameButton
            itemId={activeItemId}
            collectionName={collectionName}
            comparisonIndex={comparisonIndex}
            moduleType={moduleType}
          />
        }
      </div>
    </div>
  )
}

export const RemoveCaseFromBatchButton = ({
  caseIndex,
  isUnsaved,
  disabled,
}: {
  caseIndex: number
  isUnsaved: boolean | undefined
  disabled?: boolean
}) => {

  const dispatch = React.useContext(ModuleDispatchContext)
  const { comparisonCases } = React.useContext(ModuleStateContext)
  const comparisonCase = comparisonCases?.[caseIndex]

  return (
    <Button
      size="xs"
      className={`close-case-${caseIndex} -mr-2 ml-auto !bg-transparent !text-gray-400 hover:!text-gray-600`}
      // className="ml-auto !bg-transparent hover:!bg-gray-400 hover:!bg-opacity-40"
      style={{ color: 'inherit' }}
      title="Remove case from batch"
      // label=''
      // icon={}
      disabled={disabled}
      onClick={(e) => {
        e.stopPropagation()
        customAlert({
          message: `Remove case from batch?${isUnsaved || !comparisonCase?.savedCaseId ? ' Unsaved changes will be lost.' : ''}`,
          type: 'confirm',
          onConfirm: () => {
            // close comparison case at index
            // 
            dispatch({ type: 'removeComparisonCaseAtIndex', index: caseIndex })
          }
        });
      }}
    >
      <XIcon className="h-5 w-5" />
    </Button>
  )
}

export const EditItemNameButton = ({
  itemId,
  comparisonIndex,
  collectionName,
  moduleType,
}: {
  itemId: number;
  comparisonIndex?: number;
  collectionName: SavedItemCollectionName;
  moduleType?: string;
}) => {

  const { type } = React.useContext(ModuleStateContext)
  
  const { savedCases } = useSavedCases()
  const { savedBatches } = useSavedBatches()
  const updateCase = useUpdateCase()
  const updateBatch = useUpdateBatch()

  // Preferentially use sub module type (e.g. cement) if available, otherwise main module type.
  // This specificity is for Industry, which has "submodules" (cement, etc.) which are actually just modules, while Industry is actually more of a "module group", but is defined as a module in app.tsx because it's acting as its own module page
  const moduleTypeFromMetadata = type

  if (!moduleType) {
    moduleType = moduleTypeFromMetadata
  }

  return (
    <Button
      size="xs"
      color="gray"
      className={`ml-2 edit-item-name edit-${collectionName}-name`}
      title="Edit item name"
      onClick={(e) => {
        e.stopPropagation();
        promptName().then(name => {
          if (name) {
            if (collectionName === 'savedCases') {
              // if item is saved already, update the name
              if (savedCases?.find(c => c.id === itemId)) {
                updateCase({id: itemId, value: {
                  name: name
                }})
              }
            } else if (collectionName === 'savedBatches') {
              // if item is saved already, update the name
              if (savedBatches?.find(c => c.id === itemId)) {
                updateBatch({ id: itemId, value: {
                  name: name
                } })
              }
            }
          }
        })
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
      </svg>
    </Button>
  )
}