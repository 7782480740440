import * as React from "react"
import { ModuleDispatchContext, ModuleStateContext } from "../components/comparableResultsModule"

export const useLoadFocusStatesFromSavedCase = (savedCaseData: SavedCaseInBackendDB | undefined, caseIndex: number) => {
  const { comparisonCases } = React.useContext(ModuleStateContext)
  const comparisonCase = comparisonCases?.[caseIndex]
  // console.log('useLoadFocusStatesFromSavedCase for caseIndex:', caseIndex)
  // const savedCaseIds = comparisonCases?.map(c => c.savedCaseId).filter((id): id is number  => !!id)
  const dispatch = React.useContext(ModuleDispatchContext)

  if (
    !savedCaseData
    ||
    !savedCaseData.demo // don't run this on non-demo cases, only on demo cases
    ||
    comparisonCase?.focusedInputs?.length // don't run this if focused inputs are already present for this case
    // ||
    // comparisonCase?.isFocusModeActive // don't run this if focus mode is already enabled
  ) {
    return null
  }
  // get focus states on saved case IDs
  const focusedInputs = savedCaseData.inputs?.focusedInputs

  if (focusedInputs) {
    dispatch({ type: 'setFocusedInputsAtIndex', value: focusedInputs, index: caseIndex })
    dispatch({ type: 'toggleFocusModeAtIndex', index: caseIndex, options: { ignoreFocusLinking: true } })
  }

}
