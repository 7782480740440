import * as React from "react"
import { ModuleStateContext } from "../components/comparableResultsModule";
import { useEventListener } from "./useEventListener";

export const useRunAndSaveCaseEventListeners = (
  runCase: () => void,
  saveCase: () => void,
  caseIndex: number,
  handleDuplicate?: () => void,
) => {

  const { comparisonCases } = React.useContext(ModuleStateContext)
  const caseId = comparisonCases?.[caseIndex]?.id

  const saveAnalysisResult = (e: CustomEvent) => {
    if (e.detail.caseIndex === caseIndex) {
      saveCase();
    }
  }
  
  const runAnalysis = (e: CustomEvent) => {
    if (e.detail.caseIndex === caseIndex) {
      runCase();
    } else {
    }
  }

  const runAllAnalyses = (e: CustomEvent) => {
    runCase();
  }

  const duplicateCase = (e: CustomEvent) => {
    if (e.detail.caseIndex === caseIndex) {
      if (typeof handleDuplicate !== 'undefined') {
        handleDuplicate();
      } else {
        throw new Error('Cannot duplicate case, because there was no handleDuplicate function provided to useRunAndSaveCaseEventListeners');
      }
    }
  }

  useEventListener('saveAnalysisResult', saveAnalysisResult)
  useEventListener('runCase', runAnalysis);
  useEventListener('runAllAnalyses', runAllAnalyses);
  useEventListener('duplicateCase', duplicateCase);
}

