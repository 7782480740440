import * as Sentry from "@sentry/browser";
import { isBrowser } from ".";


export type Client = {
  body?: Record<string, unknown>;
  headers?: Record<string, string>;
  method?: ClientRequestMethod;
};

// this is a bare-metal dupe of the logout() method in useAuth.ts, because we can't access a function inside a React hook from a pure JS function
const logout = () => {
  if (isBrowser()) {
    localStorage.setItem('authToken', '')
  }
}

/**
 * Built on baremetal client() function, but also sends Bearer auth token.
 * Only works if user is logged in.
 * This is an alternative to useClient() for when this functionality is needed
 * outside of a React component or hook.
 */
export const authenticatedClient = (
  endpoint: string,
  { 
    body,
    headers = {},
    method
  }: {
    body?: any,
    headers?: any,
    method?: ClientRequestMethod | undefined
  }
) => {
  const token = localStorage.getItem('authToken')
  // const isAuthenticated = !!token
  console.log(token)

  if (!token) {
    throw new Error('No auth token available')
  }

  // const httpClient = useCallback(
  // return async (endpoint: string, { body, headers = {}, method }: Client = {}) => {
  // if (isAuthenticated) {
  headers["Authorization"] = `Bearer ${JSON.parse(token)}`;

  console.log(headers, method, body)
  // }

  //   try {
  return client(endpoint, { body, headers, method });
    // } catch (res) {
      // TODO: it would be better to check for a 401 status code here
      // since "unauthorized" could also mean a permissions-like error
      // `client` does not currently expose the HTTP status
      // if (res.errors === "unauthorized") {
        // logout();
      // } else {
        // throw res;
      // }
    // }
  // }
    // [isAuthenticated, logout, token]
  // )
}

/**
 * Bare-metal impementation of client() to fetch from backend API
 * Does not send user Authentication header
 */
function client(
  endpoint: string,
  { body, headers = {}, method }: Client = {}
): Promise<Record<string, unknown> | object> {
  headers["Content-Type"] = "application/json";

  const config: {
    method: ClientRequestMethod;
    headers: Record<string, string>;
    body?: string;
  } = {
    method: method ?? (body ? "POST" : "GET"),
    headers,
  };

  if (body) {
    config.body = JSON.stringify(body);
  }

  return window
    .fetch(`${process.env.API_URL}${endpoint}`, config)
    .then(async (response) => {
      // console.log(response)
      if (response.ok) {
        // handle 204 No Content response, i.e. request was successful but no content returned
        if (response.status === 204) {
          return { success: true }
        } else if (response.status === 404) {
          return { success: false }
        } else {
          const data = await response.json();
          return data;
        }
      } else {
        if (response.status === 401) {
          logout()
        }
        // log timeouts to Sentry
        if (response.status === 504) {
          Sentry.captureEvent({
            message: `API request timed out`,
            transaction: 'timeout',
            extra: {
              endpoint,
              err: JSON.stringify(err),
            },
          });
        }
        return Promise.reject();
      }
    }).catch(e => {
      console.log(e)
    });
}

export default client;
