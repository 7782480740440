import * as React from "react"
import { ModuleDispatchContext } from "../components/comparableResultsModule"
import { customAlert } from "../components/customAlert"
import useClient from "./useClient"
import { useRefreshSavedBatches, useRefreshSavedCases } from "./useDB"

export const useDeleteSavedItemIds = (type: SavedItemCollectionName) => {

  const refreshSavedCases = useRefreshSavedCases()
  const refreshSavedBatches = useRefreshSavedBatches()
  const dispatch = React.useContext(ModuleDispatchContext)
  const { client } = useClient()

  const deleteSavedItemIds = async (itemIds: number[]) => {

    const apiSubPath = type === 'savedCases' ? 'cases' : 'batches'
    const itemNamePlural = apiSubPath
    const itemNameSingular = type === 'savedCases' ? 'case' : 'batch'

    client(`/${apiSubPath}/bulk_delete/`, {
      method: 'POST',
      body: {
        ids: itemIds
      }
    }).then(response => { // response returns as { ids: number[] } object
      console.log(response)
      if (response?.ids) {
        // need to update module state now to remove deleted cases
        if (type === 'savedCases') {
          if (typeof dispatch === 'function') {
            dispatch({type: 'deleteSavedCaseIds', value: itemIds})
          }
          refreshSavedCases({ caseIds: itemIds })
        } else if (type === 'savedBatches') {
          if (typeof dispatch === 'function') { 
            dispatch({type: 'setBatch', value: undefined})
          }
          refreshSavedBatches({ batchIds: itemIds })
          // TODO also should refresh any caseIds included in this batch
        }
        const numItemsDeleted = response?.ids.length
        customAlert.success(`Deleted ${numItemsDeleted} ${numItemsDeleted > 1 ? itemNamePlural : itemNameSingular}`)
      } else {
        customAlert.error('Error deleting item')
      }
    })//.catch(error => {
    //   console.log('CATCH ERROR')
    //   customAlert.error('Error deleting item')
    // })

    // TODO need to implement all the below logic on the backend, i.e. removing cases from batches that include them
    // removeCaseIdsFromBatchesThatIncludeThem({ caseIds: caseIds }),
  }

  return deleteSavedItemIds
}