import { XIcon } from "@heroicons/react/solid"
import * as React from "react"
import Accordion from "./accordion"
import { getCaseNameFromComparisonCaseAtIndex, ModuleDispatchContext, ModuleStateContext } from "./comparableResultsModule"
import { customAlert } from "./customAlert"
import { BulkActionButton } from "./pages/savedCaseBrowser"
import { SavedCaseName } from "./savedCaseName"
import { RemoveCaseFromBatchButton, SavedItemMenu } from "./savedItemMenu"
import { Button, Loader } from "./styles"

export const BatchCaseList = () => {

  const { comparisonCases, caseIdsActiveInComparisonView } = React.useContext(ModuleStateContext)
  const dispatch = React.useContext(ModuleDispatchContext)

  // const [caseIdsToViewInputsFor, setCaseIdsToViewInputsFor] = React.useState<number[]>([])

  // const toggleCaseId = (id: number) => {
  //   if (caseIdsToViewInputsFor.includes(id)) {
  //     setCaseIdsToViewInputsFor(caseIds => caseIds.filter(caseId => caseId !== id))
  //   } else {
  //     setCaseIdsToViewInputsFor(caseIds => [...caseIds, id])
  //   }
  // }

  return (
    // <div className="w-full py-2 !p-0 rou">

    <div className="w-full !p-0 mb-2">
      <Accordion title={"Cases in Batch"} headerClassName="!rounded-none" defaultOpen={true}>
        {/* <div className="flex-grow-0 font-bold text-gray-700 bg-gray-100 border-t border-b py-2 px-4 mb-2 w-full">Active Cases in Batch</div> */}
        <div className="w-full space-y-0 px-4 pt-2 pb-[2px]">
          {comparisonCases?.map((comparisonCase, index) => {
            const isInComparison = caseIdsActiveInComparisonView?.includes(comparisonCase.id)
            return (
              // <div className={`w-full flex items-center py-1 ${isInComparison ? 'bg-gray-700 text-gray-200' : 'bg-gray-200 text-gray-700'} pl-3 pr-1 rounded`}>

              <div className={`w-full flex items-center`}>
                {/* <input type="checkbox" checked={isInComparison} onChange={(e) => toggleCaseId(comparisonCase.id)}/> */}
                <span className={`${isInComparison ? 'font-bold' : ''} overflow-hidden whitespace-nowrap overflow-ellipsis`}>
                  <SavedCaseName comparisonIndex={index} />
                </span>
                <span className="ml-auto flex items-center space-x-2">
                  {comparisonCase?.isRunning
                    ?
                    <Loader size="small" className="py-1" />
                    :
                    <RemoveCaseFromBatchButton
                      caseIndex={index}
                      isUnsaved={comparisonCase.isUnsaved}
                      disabled={comparisonCase?.isRunning}
                    />
                  }
                </span>
              </div>
            )
          })}
        </div>
      </Accordion>
      <div className="px-4 py-2 w-full">
        <SavedItemMenu
          // comparisonIndex={comparisonCases?.length}
          // activeItem={comparisonCase}
          // activeItemId={comparisonCase?.savedCaseId}
          maxHeight="40vh"
          showLabel={false}
          placeholder="Add case to batch"
          collectionName="savedCases"
          // itemTitle="Case"
          createCaseMethod="append"
        // isUnsaved={comparisonCase?.isUnsaved}
        // displayActiveCasesInBatch={false}
        />
      </div>
    </div>
    //</div>
  )
}