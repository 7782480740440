import { debounce } from "lodash";
import * as React from "react"
import { useSavedCaseDetail } from "../hooks/useDB";
import { useLoadFocusStatesFromSavedCase } from "../hooks/useLoadFocusStatesFromSavedCase";
import Accordion from "./accordion";
import { ModuleDispatchContext, ModuleStateContext } from "./comparableResultsModule";
import { Label, Select } from "./styles";
import UserInputs, { getPrefixedInputName, InputBlock } from "./userInputs";

const openAccordionIfStageHasInvalidInputs = ({
  userInputsAtThisStage,
  stageInputHandler,
  areThereMultipleNodeOptionsAtThisStage,
  setIsOpen,
}: {
  userInputsAtThisStage: UserInputProperties[] | undefined;
  stageInputHandler: InputHandler;
  areThereMultipleNodeOptionsAtThisStage: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  if ((userInputsAtThisStage && userInputsAtThisStage?.length > 0 && Object.keys(stageInputHandler.inputStates)?.length > 0 && !stageInputHandler.isValid) || areThereMultipleNodeOptionsAtThisStage) {
    setIsOpen(true);
  } else {
    // setIsOpen(false)
  }
}

const areStagePropsEqual = (prevProps: StageProps, nextProps: StageProps) => {
  const propsToCompare = ['nodesChosen', 'dataSourcesChosen', 'nodeOptionsAtEachStage', 'stageInputHandler', 'comparisonIndex', 'isOpen', 'focusedInputs'];
  if (typeof prevProps === 'undefined' && typeof nextProps === 'undefined') {
    return true;
  }
  if (!prevProps || !nextProps) {
    return false;
  }
  const areEqual = propsToCompare.every(prop => {
    return JSON.stringify(prevProps[prop as keyof StageProps]) === JSON.stringify(nextProps[prop as keyof StageProps]);
  })
  return areEqual;
}

interface StageProps {
  stage: Stage,
  stages: Stage[],
  index: number,
  nodesChosen: Array<string | null>;
  dataSourcesChosen: Array<string | null>;
  nodeOptionsAtEachStage: Array<string[] | undefined>;
  setNodeChosenAtIndex: (nodeChosen: string | null, index: number) => void;
  setDataSourceChosenAtIndex: (dataSourceChosen: string | null, index: number) => void;
  stageInputHandler: InputHandler;
  colIndex: number;
  caseIndex: number;
  isOpen: boolean;
  toggleInputGroupOpenState: (name: string) => void;
  focusedInputs: string[];
}

// just render all stages, but if they're done already, set them to display as done
export const PathwayCreatorStage = ({
  stage,
  stages,
  index,
  nodesChosen,
  dataSourcesChosen,
  nodeOptionsAtEachStage,
  setNodeChosenAtIndex,
  setDataSourceChosenAtIndex,
  stageInputHandler,
  colIndex,
  caseIndex,
  isOpen,
  toggleInputGroupOpenState,
  focusedInputs,
}: StageProps) => {

  // this complicated thing handles the automatic opening of accordions that have invalid inputs inside (debounced to avoid jank) - we need one of these debounced functions per stage b/c we have one user inputs handler and accordion isOpen handler per stage
  const debouncedOpenAccordionIfStageHasInvalidInputs = React.useRef({
    func: debounce(openAccordionIfStageHasInvalidInputs, 1000, {leading: false, trailing: true})
  });

  const { comparisonCases } = React.useContext(ModuleStateContext)
  const comparisonCase = comparisonCases?.[caseIndex]
  const isFocusModeActive = comparisonCase?.isFocusModeActive

  const dispatch = React.useContext(ModuleDispatchContext);

  const { savedCaseData } = useSavedCaseDetail(comparisonCase?.savedCaseId)

  useLoadFocusStatesFromSavedCase(savedCaseData, caseIndex)

  const activityAtThisStage = stage.activities?.find(activity => { return activity.id.indexOf(nodesChosen[index] as string) > -1 })
  const dataSourceAtThisStage = dataSourcesChosen[index];
  const userInputsAtThisStage = (
    dataSourceAtThisStage
    ?
    activityAtThisStage?.sources?.find(source => source.id === dataSourceAtThisStage)?.user_inputs
    :
    activityAtThisStage?.sources[0]?.user_inputs
  );
  const areThereMultipleNodeOptionsAtThisStage = nodeOptionsAtEachStage[index]?.length > 1;
  const hasNodeBeenChosenAtThisStage = nodesChosen[index];
  let shouldDisplayThisStage = (
    areThereMultipleNodeOptionsAtThisStage
    ||
    (hasNodeBeenChosenAtThisStage && userInputsAtThisStage && userInputsAtThisStage.length > 0)
  );

  // if (isFocusModeActive) {
  //   // check if any inputs are focused - if not, don't display this stage
  //   let areAnyVisibleInputs = false;
  //   const checkIsInputFocused = (userInput: UserInputProperties) => {
  //     // only do this check for inputs that are visible (i.e. their conditionals check out)
  //     if ((userInput?.conditionals as Conditional[])?.every(conditional => checkConditional(conditional, stageInputHandler.inputStates))) {
  //       if (focusedInputs?.includes(userInput.name)) {
  //         areAnyVisibleInputs = true;
  //       }
  //     }
  //   }
  //   userInputsAtThisStage?.map(userInput => {
  //     if (userInput.type === 'group') {
  //       userInput.children.map(userInput => {
  //         checkIsInputFocused(userInput)
  //       })
  //     } else {
  //       checkIsInputFocused(userInput)
  //     }
  //   })
  //   if (!areAnyVisibleInputs) {
  //     shouldDisplayThisStage = false;
  //   }
  // }

  React.useEffect(() => {
    // setIsOpen(false);
    debouncedOpenAccordionIfStageHasInvalidInputs.current.func({
      userInputsAtThisStage,
      stageInputHandler,
      areThereMultipleNodeOptionsAtThisStage,
      setIsOpen: () => toggleInputGroupOpenState(stage.name || ''),
    })
  }, [stageInputHandler.isValid, stageInputHandler.inputStates, areThereMultipleNodeOptionsAtThisStage])

  // this is used for fetching input data from backend I think?
  // const currentSourceId = activityAtThisStage?.sources[0]?.id;
  const availableSourcesAtThisStage = activityAtThisStage?.sources;
  const nodeOptionsAtThisStage = nodeOptionsAtEachStage[index];
  const nodeChosenAtThisStage = nodesChosen[index];
  const stageName = stage.name?.length === 3 ? stage.name : stage.name?.split(/(?=[A-Z])/).join(' ') || ''; // hack so that TEA doesn't get spaces between it

  const stageContent = (
    <div>
      {areThereMultipleNodeOptionsAtThisStage &&
        <>
          <InputBlock>
            <Label className="col-span-2">{stageName} type</Label>
            <Select value={nodeChosenAtThisStage || ''} onChange={(e) => { setNodeChosenAtIndex(e.target.value, index) }}>
              <option disabled={true} selected={true} label={"Choose one"} />
              {nodeOptionsAtThisStage.map(nodeOption => {
                const nodeLabel = stages[index]?.activities?.find(activity => activity.id === nodeOption)?.name;
                return (
                  <option value={nodeOption} label={nodeLabel}></option>
                )
              }
              )}
            </Select>
          </InputBlock>
          {/* <hr className="my-4" /> */}
        </>
      }
      {availableSourcesAtThisStage && availableSourcesAtThisStage.length > 1 && !isFocusModeActive &&
        <InputBlock className="mb-0">
          <Label className="col-span-2">Data Source</Label>
          <Select
            value={dataSourcesChosen[index]}
            id="user-inputs--select-data-source"
            onChange={(e) => {
              const newDataSource = e.target.value;
              dispatch({type: 'clearCaseAtComparisonColumnIndex', index: colIndex})
              setDataSourceChosenAtIndex(newDataSource, index);
            }}
          >
            {availableSourcesAtThisStage.map(source => (
              <option value={source.id} label={source.name}>{source.name}</option>
            ))}
          </Select>
        </InputBlock>
      }
      {userInputsAtThisStage &&
        <UserInputs
          userInputs={userInputsAtThisStage}
          inputStates={stageInputHandler.inputStates}
          comparisonIndex={colIndex}
          setInput={(name, value, opts) => {
            dispatch({type: 'clearCaseAtComparisonColumnIndex', index: colIndex})
            stageInputHandler.setInput(name, value, opts);
          }}
          inputNamePrefix={nodeChosenAtThisStage ?? ''} // pass empty string if nodeChosen is null
          setInputError={stageInputHandler.setInputError}
        />
      }
    </div>
  )

  if (!shouldDisplayThisStage) {
    return null;
  }

  const areAnyFocusedInputs = Object.keys(stageInputHandler.inputStates ?? {}).some(inputName => {
    return focusedInputs?.includes(getPrefixedInputName(inputName, nodeChosenAtThisStage ?? ''))
  })

  return (
    <>
      {isFocusModeActive
        ?
        stageContent
        :
        <Accordion
          title={stageName}
          // isOpen={inputGroupOpenStates?.[stageName]}
          // setIsOpen={(e) => {
          //   dispatch({type: 'toggleInputGroupOpenStateAtComparisonIndex', value: stageName, index: comparisonIndex})
          // }}
          isOpen={isOpen}
          setIsOpen={(e) => {
            toggleInputGroupOpenState && toggleInputGroupOpenState(stage.name || '');
          }}
          headerContentWhenClosed={
            <>
              {areAnyFocusedInputs && <div className="ml-auto mr-2 rounded bg-gray-200 text-gray-500 text-sm py-[1px] px-2">Has focus selections</div>}
            </>
          }
          padContentTop={true}
        >
          {stageContent}
        </Accordion>
      }
    </>
    
  )
}//, areStagePropsEqual)