import * as React from "react"
import { Menu, Transition } from '@headlessui/react'
import { DotsVerticalIcon } from "@heroicons/react/solid"
import { ModuleDispatchContext } from "../comparableResultsModule"

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const ModuleMenu = ({

}: {

}) => {

  const dispatch = React.useContext(ModuleDispatchContext)

  return (
    <Menu as="div" className="relative !px-0">
      <div>
        <Menu.Button className="inline-flex justify-center w-8 rounded-md border border-gray-300 shadow-sm p-1 bg-white text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
          <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : '',
                    'block px-4 py-1'
                  )}
                  onClick={(e) => {
                    dispatch({type: 'resetComparisonCasesToOneEmptyCase'})
                  }}
                >
                  Reset module
                </a>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}