import * as React from "react"
import addToMailchimp from "gatsby-plugin-mailchimp";

export const EmailSignup = ({
  theme,
}: {
  theme: 'dark' | 'light'
}) => {
  const [email, setEmail] = React.useState<string>();
  const [mcResponse, setMcResponse] = React.useState<MailChimpResponse>();
  const handleSubmit = (
    e: React.FormEvent<HTMLFormElement>,
    userEmail?: string
  ) => {
    e.preventDefault();
    addToMailchimp(userEmail)
      .then((data: MailChimpResponse) => {
        setMcResponse(data);
      })
      .catch(() => {
        // unnecessary because MailChimp only ever returns a 200 status code
      });
  };

  return (
    <div>
      <p className={`text-base font-medium lg:max-w-sm`}>
        Sign up to become a beta user:
      </p>
      <form
        onSubmit={(e) => handleSubmit(e, email)}
        className="mt-3 sm:flex"
      >
        <input
          type="email"
          required
          onChange={(e) => setEmail(e.target.value)}
          aria-label="Email"
          className="appearance-none block w-full px-3 py-3 border border-gray-400 text-base leading-6 rounded-md placeholder-gray-500 shadow-sm focus:outline-none focus:placeholder-gray-400 focus:shadow-outline focus:border-blue-300 transition duration-150 ease-in-out sm:flex-1 sm:max-w-xs"
          placeholder="Enter your email"
        />
        <button
          type="submit"
          className="mt-3 w-full px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-800 shadow-sm hover:bg-gray-700 focus:outline-none focus:shadow-outline active:bg-gray-900 transition duration-150 ease-in-out sm:mt-0 sm:ml-3 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto"
        >
          Sign up
        </button>
      </form>
      {mcResponse && mcResponse.result === "error" && (
        <div
          className="mt-4 text-red-800"
          dangerouslySetInnerHTML={{ __html: mcResponse.msg }}
        />
      )}
      {mcResponse && mcResponse.result === "success" && (
        <div
          className="mt-4 text-blue-800"
        >
          You’re on the list! You’ll receive an email when the beta is released. If you have any questions, please contact <a href="mailto:sesame@mit.edu">sesame@mit.edu</a>.
        </div>
      )}
    </div>
  )
}